import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import axios from "axios";
import Delet from "./Delet";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useSelector } from "react-redux";
import NotFound_Icons from "../assets/no_res.svg";

const Stock = () => {
  const [rows, setrows] = useState([]);
  const [item, setitems] = useState([]);
  const [show, setshow] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Delet1, setDelet1] = useState(false);
  const [showdetail, setshowdetail] = useState(false);
  const [ValueOfDelete, setValueOfDelete] = useState(0);
  const [MsgDelete, setMsgDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const select = useSelector((state) => {
    return state;
  });
  const HandleSearch = (e) => {
    let a = [];
    a = rows.filter((item) => {
      return (
        (item.desgination || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.reference || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.description || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.note || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.pv || "").toLowerCase().includes(e.target.value.toLowerCase()) ||
        (item.stock_min || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.stock_max || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    setshow(
      a.map((row) => (
        <TableRow
          key={row.id}
          style={
            row.qte_prod <= row.stock_min
              ? { background: "#FA7C6D" }
              : row.qte_prod >= row.stock_max
              ? { background: "#C7F4CC" }
              : null
          }
        >
          <TableCell align="left">
            <img
              height={20}
              width={20}
              src={process.env.REACT_APP_IMAGE + "" + row.photo}
            ></img>
          </TableCell>
          <TableCell align="center">{row.desgination}</TableCell>
          <TableCell align="center">{row.reference}</TableCell>
          <TableCell align="center">{row.qte_prod}</TableCell>
          <TableCell align="center">{row.pv}</TableCell>
          <TableCell align="center">{row.pa}</TableCell>
          <TableCell align="center">{row.description}</TableCell>
          <TableCell align="center">{row.note}</TableCell>
          <TableCell align="center">{row.code_bar}</TableCell>
        </TableRow>
      ))
    );
  };
  const navigate = useNavigate();

  {
    /* <TableCell align="left">
            {" "}
            <img
              height={20}
              width={20}
              src={process.env.REACT_APP_IMAGE + "" + row.image}
            ></img>
          </TableCell> */
  }
  useEffect(() => {
    setLoading1(true);
    const fetchData = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + "articles", {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((resp) => {
          setrows(resp.data);

          if (resp.data.length > 0) {
            setshow(
              resp.data.map((row) => (
                <TableRow
                  key={row.id}
                  style={
                    row.qte_prod <= row.stock_min
                      ? { background: "#FA7C6D" }
                      : row.qte_prod >= row.stock_max
                      ? { background: "#C7F4CC" }
                      : null
                  }
                >
                  <TableCell align="left">
                    <img
                      height={20}
                      width={20}
                      src={process.env.REACT_APP_IMAGE + "" + row.photo}
                    ></img>
                  </TableCell>
                  <TableCell align="center">{row.desgination}</TableCell>
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.qte_prod}</TableCell>
                  <TableCell align="center">{row.pv}</TableCell>
                  <TableCell align="center">{row.pa}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.note}</TableCell>
                  <TableCell align="center">{row.code_bar}</TableCell>
                </TableRow>
              ))
            );
          } else {
            setshow(null);
          }
          setLoading1(false);
        });
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (ValueOfDelete == 0) {
    } else {
      axios
        .delete(process.env.REACT_APP_API_URL + "destroy_article/" + item.id, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((resp) => {
          if (resp.data == "") {
            setrows([]);
            setshow([]);
          } else {
            setrows(resp.data);
            setshow(
              resp.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="left">
                    <img
                      height={20}
                      width={20}
                      src={process.env.REACT_APP_IMAGE + "" + row.photo}
                    ></img>
                  </TableCell>
                  <TableCell align="center">{row.desgination}</TableCell>
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.qte_prod}</TableCell>
                  <TableCell align="center">{row.pv}</TableCell>
                  <TableCell align="center">{row.pa}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">{row.note}</TableCell>
                  <TableCell align="center">{row.code_bar}</TableCell>
                </TableRow>
              ))
            );
          }
        });

      setValueOfDelete(0);
    }
  }, [ValueOfDelete]);

  return (
    <>
      {" "}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{MsgDelete}</Alert>
      </Snackbar>{" "}
      {showdetail == true ? <></> : null}
      {Delet1 == true ? (
        <Delet
          // setValueOfDelete={setValueOfDelete}
          // setvalue={setDelet1}
          Title={"Supprimer"}
          Message={"Etes-vous sûr que vous voulez supprimer?"}
          LabelOne={"Annuler"}
          FunctionOne={() => {
            setDelet1(false);
          }}
          LabelTwo={"Supprimer"}
          FunctionTwo={() => {
            setValueOfDelete(1);
            setDelet1(false);
          }}
        />
      ) : null}
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="admin-g-s">
            <div className="admin-g-titre">
              <h1>Stock</h1>
            </div>
            <div className="admin-g-search">
              <form className="admin-form">
                <div className="container-search ">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                    className="search-input-mobile w-full"
                    placeholder="Produit ..."
                    id="search"
                    onChange={HandleSearch}
                  />
                </div>
              </form>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="t-header">
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell align="center">Designation</TableCell>
                  <TableCell align="center">Reference</TableCell>
                  <TableCell align="center">Qte</TableCell>
                  <TableCell align="center">Prix Vente</TableCell>
                  <TableCell align="center">Prix Achat</TableCell>
                  <TableCell align="center">description</TableCell>
                  <TableCell align="center">Note</TableCell>
                  <TableCell align="center">CodeBarr</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{show}</TableBody>
            </Table>
          </TableContainer>
          {show == null ? (
            <>
              <div className="not_found_container">
                <img
                  className="not_found"
                  src={NotFound_Icons}
                  alt="React Logo"
                />
                <span className="color_primir">Aucun résultat</span>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
export default Stock;
