import React from "react";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Loading from "../loading/Loading";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert } from "@mui/lab";

export default function Organisation(props) {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(false);
  const [success, setsuccess] = useState(false);
  //
  //Varib Group
  const navigate = useNavigate();

  const [ObjectClient, setObjectClient] = useState({
    raison_sociale: null,
    adresse: null,
    adresse_suite: null,
    code_postal: null,
    ville: null,
    pays: null,
    tele: null,
    email: null,
    logo: null,
  });
  const [s2, sset2] = useState(null);

  const OnchangeImage2 = (e) => {

    let a = ObjectClient;
    sset2(URL.createObjectURL(e.target.files[0]));
    a.logo = e.target.files[0];
    setObjectClient(a);
  };
  const HandleChange = (e) => {
    let a = ObjectClient;
    switch (e.target.id) {
      case "raison_sociale":
        a.raison_sociale = e.target.value;
        setObjectClient(a);
        break;
      case "adresse":
        a.adresse = e.target.value;
        setObjectClient(a);
        break;
      case "adresse_suite":
        a.adresse_suite = e.target.value;
        setObjectClient(a);
        break;
      case "code_postal":
        a.code_postal = e.target.value;
        setObjectClient(a);
        break;
      case "ville":
        a.ville = e.target.value;
        setObjectClient(a);
        break;
      case "pays":
        a.pays = e.target.value;
        setObjectClient(a);
        break;
      case "tele":
        a.tele = e.target.value;
        setObjectClient(a);
        break;
      case "email":
        a.email = e.target.value;
        setObjectClient(a);
        break;
    }
  };

  const GetSocete = async () => {
    axios.get(process.env.REACT_APP_API_URL + "GetSocete").then((res) => {
      setObjectClient(res.data);
      sset2(process.env.REACT_APP_IMAGE + "" + res.data.logo);
    });
  };

  const HandleUpdate = async () => {
    const formData = new FormData();
    if (ObjectClient.adresse !== null) {
      formData.append("adresse", ObjectClient.adresse);
    }
    if (ObjectClient.adresse_suite !== null) {
      formData.append("adresse_suite", ObjectClient.adresse_suite);
    }
    if (ObjectClient.code_postal !== null) {
      formData.append("code_postal", ObjectClient.code_postal);
    }
    if (ObjectClient.email !== null) {
      formData.append("email", ObjectClient.email);
    }
    if (ObjectClient.logo !== null) {
      formData.append("logo", ObjectClient.logo);
    }
    if (ObjectClient.pays !== null) {
      formData.append("pays", ObjectClient.pays);
    }
    if (ObjectClient.raison_sociale !== null) {
      formData.append("raison_sociale", ObjectClient.raison_sociale);
    }
    if (ObjectClient.tele !== null) {
      formData.append("tele", ObjectClient.tele);
    }
    if (ObjectClient.ville !== null) {
      formData.append("ville", ObjectClient.ville);
    }

    axios
      .post(process.env.REACT_APP_API_URL + "UpdateSocete", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((res) => {
   
        setsuccess(true);
      });
  };
  useEffect(() => {
    GetSocete();
  }, []);
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">Société</div>
          {/* <form className="form-group"> */}
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                Modifiez le nom de votre Société et les informations nécessaires
                à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Raison Sociale</label>
                <input
                  defaultValue={ObjectClient.raison_sociale}
                  type="text"
                  id="raison_sociale"
                  onChange={HandleChange}
                  placeholder="Raison sociale ..."
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Adresse</label>
                <input
                  defaultValue={ObjectClient.adresse}
                  type="text"
                  id="adresse"
                  placeholder="Adresse ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Adresse 2</label>
                <input
                  defaultValue={ObjectClient.adresse_suite}
                  type="text"
                  id="adresse_suite"
                  placeholder="Adresse 2"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>code Postal </label>
                <input
                  defaultValue={ObjectClient.code_postal}
                  type="text"
                  id="code_postal	"
                  placeholder="Code Postal	 ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Ville</label>
                <input
                  defaultValue={ObjectClient.ville}
                  type="ville"
                  id="ville"
                  placeholder="Ville"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Tele</label>
                <input
                  defaultValue={ObjectClient.tele}
                  type="tel"
                  id="tele"
                  placeholder="Tele .."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Email</label>
                <input
                  defaultValue={ObjectClient.email}
                  type="email"
                  id="email"
                  placeholder="Example@Example.com"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <label htmlFor="file-upload2" className="custom-file-upload">
                Logo
              </label>
              <input
                id="file-upload2"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={OnchangeImage2}
              />
              <img className="img-show" src={s2}></img>
            </div>
          </div>
          {success == true ? (
            <Alert severity="success">Socete est modifer </Alert>
          ) : null}
          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleUpdate}>
              Modifée
            </button>
          </div>

          {/* </form> */}
        </div>
      )}
    </>
  );
}
