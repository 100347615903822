import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function AddArtcile(props) {
  const [myproduit, setmyproduit] = useState(null);
  const [montant, setmontant] = useState(0);
  const [typeverre, settypeverre] = useState(null);
  const select = useSelector((state) => {
    return state;
  });
  const [ListProduct, setListProduct] = useState([]);
  const GestArticles = () => {
      axios
        .get(process.env.REACT_APP_API_URL + "articles", {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setListProduct(res.data);
          }
        });
  };
  useEffect(() => {
    GestArticles();
  }, []);
  return (
    <div className="join">
      <div className="recptipn-container">
        <article style={{ minWidth: 600 }}>
          <div className="close-menu">
            <div
              className="panier-close"
              onClick={() => {
                props.setvalue(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <center>
            <h4>Ajouter Article</h4>
            {/* Hadi t ajouter type verre */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: 10,
                flexDirection: "row",
              }}
            >
              <Autocomplete
                onChange={(event, newValue) => {
                  settypeverre(newValue);
                }}
                options={[
                  { type: "1.5 blanc" },
                  { type: "1.5Ar" },
                  { type: "1.56blanc " },
                  { type: "1.56Ar" },
                  { type: "1.6 Ar" },
                  { type: "1,67Ar" },
                  { type: "1,74Ar" },
                  { type: "1,56Photogris /blanc" },
                  { type: "1.56photogris/Ar" },
                  { type: "Photogris /bleu" },
                  { type: "1,56 filtre bleu" },
                  { type: "1,6 bleu" },
                  { type: "1,67bleu" },
                  { type: "Progressif blanc" },
                  { type: "Progressif Ar" },
                  { type: "Progressif photogris/blanc" },
                  { type: "Progressif photogris/AR" },
                ]}
                style={{ width: "100%" }}
                getOptionLabel={(option) => option.type}
                renderInput={(params) => (
                  <TextField {...params} label="Type Verre ..." />
                )}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                }}
              >
                <label>Montant </label>
                <input
                  defaultValue={montant}
                  placeholder="0"
                  type="number"
                  onChange={(e) => {
                    setmontant(e.target.value);
                  }}
                ></input>
              </div>{" "}
              {/* Hadi ajouter type verre */}
              <span
                onClick={() => {
                  if (typeverre != null) {
                    props.listdetail.push({
                      id_facture_vente: props.command.id,
                      prix: montant,
                      desgination: typeverre.type,
                      id_article: "9999",
                      total: montant,
                      qte: 1,
                    });
                    props.setvalue(false);
                  }
                }}
                style={{
                  padding: 10,
                  background: "#009F7F",
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 25,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Ajouter
              </span>
            </div>{" "}
            {/* Hadi t ajouter produit */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: 10,
                flexDirection: "row",
              }}
            >
              <Autocomplete
                onChange={(event, newValue) => {
                  setmyproduit(newValue);
                }}
                options={ListProduct}
                style={{ width: "100%" }}
                getOptionLabel={(option) =>
                  option.desgination + " " + option.reference
                }
                renderInput={(params) => (
                  <TextField {...params} label="Produit ..." />
                )}
              />

              <span
                onClick={() => {
                  if (myproduit != null) {
                    props.listdetail.push({
                      id_facture_vente: props.command.id,
                      prix: myproduit.pv,
                      desgination: myproduit.desgination,
                      id_article: myproduit.id,
                      total: myproduit.pv,
                      qte: 1,
                    });
                    props.setvalue(false);
                  }
                }}
                style={{
                  padding: 10,
                  background: "#009F7F",
                  color: "#fff",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 25,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Ajouter
              </span>
            </div>{" "}
          </center>
        </article>
      </div>
    </div>
  );
}
