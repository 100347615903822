import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import P404 from "./p404";
import AdminHome from "./Admin/AdminHome";
import ClientForm from "./Admin/ClientForm";

// import Groups from "./Admin/Groups";
// import GroupFrom from "./Admin/GroupForm";
// import Category from "./Admin/Category";
// import CategoryFrom from "./Admin/CategoryForm";
// import SousCat from "./Admin/SousCat";
// import SousCatForm from "./Admin/SousCatForm";
// import Offre from "./Admin/Offre";
// import OffreForm from "./Admin/OffreForm";
// import ProduitForm from "./Admin/ProduitForm";
// import Produit from "./Admin/Produit";
// import ReceptionForm from "./Admin/ReceptionForm";
// import Reception from "./Admin/Reception";
// import Livreur from "./Admin/Livreur";
// import LivreurFrom from "./Admin/LivreurForm";
// import CommandTrait from "./Admin/CommandTrait";
// import CommandEnattend from "./Admin/CommandEnattend";
// import CommandEnattendForm from "./Admin/CommandEnattendForm";
// import Home from "./Page/home";
// import Test from "./Page/Test";

import Client from "./Admin/Client";
import Login from "./Components/Login";
import Fournisseur from "./Admin/Fournisseur";
import FournisseurForm from "./Admin/FournisseurForm";
import Produit from "./Admin/Produit";
import ProduitForm from "./Admin/ProduitForm";
import Marque from "./Admin/Marque";
import MarqueForm from "./Admin/MarqueForm";
import Testme from "./Testme";
import POS from "./Admin/Pos.js";
import CommandTrait from "./Admin/CommandTrait";
import Stock from "./Admin/Stock";
import Caisse from "./Admin/Caisse";
import Dashbord from "./Admin/Dashbord";
import Facture from "./Admin/Facture";
import Reception from "./Admin/Reception";
import ReceptionForm from "./Admin/ReceptionForm";
import Organisation from "./Admin/Organisation";
import Parametre from "./Admin/Parametre";
import User from "./Admin/User";
import UserForm from "./Admin/UserForm";
import UpdateCommande from "./Admin/UpdateCommande";

// 1 create  2 edit
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/dashbord" element={<AdminHome comp={<Dashbord />} />} />
          <Route path="/" element={<Login />} />{" "}
          <Route path="/pos" element={<POS />} />{" "}
          <Route path="/modife_commande" element={<UpdateCommande />} />{" "}
          <Route
            path="/societe"
            element={<AdminHome comp={<Organisation />} />}
          />
          <Route
            path="/parametre"
            element={<AdminHome comp={<Parametre />} />}
          />
          <Route path="/utilisateur" element={<AdminHome comp={<User />} />} />
          <Route path="/admin" element={<AdminHome comp={<Client />} />} />
          <Route path="/clients" element={<AdminHome comp={<Client />} />} />
          <Route path="/stock" element={<AdminHome comp={<Stock />} />} />
          <Route path="/caisse" element={<AdminHome comp={<Caisse />} />} />
          <Route path="/facture" element={<AdminHome comp={<Facture />} />} />
          <Route path="/achat" element={<AdminHome comp={<Reception />} />} />
          <Route
            path="/achat/ajouter"
            element={<AdminHome comp={<ReceptionForm />} />}
          />
          <Route
            path="/commande"
            element={<AdminHome comp={<CommandTrait />} />}
          />
          <Route
            path="/client/ajouter"
            element={<AdminHome comp={<ClientForm stat={1} />} />}
          />
          <Route
            path="/client/edit"
            element={<AdminHome comp={<ClientForm stat={2} />} />}
          />
          <Route
            path="/fournisseur"
            element={<AdminHome comp={<Fournisseur />} />}
          />
          <Route
            path="/fournisseur/ajouter"
            element={<AdminHome comp={<FournisseurForm stat={1} />} />}
          />
          <Route
            path="/fournisseur/edit"
            element={<AdminHome comp={<FournisseurForm stat={2} />} />}
          />
          {/* Produit */}
          <Route path="/produit" element={<AdminHome comp={<Produit />} />} />
          <Route
            path="/produit/ajouter"
            element={<AdminHome comp={<ProduitForm stat={1} />} />}
          />
          <Route
            path="/produit/edit"
            element={<AdminHome comp={<ProduitForm stat={2} />} />}
          />
          <Route path="/marque" element={<AdminHome comp={<Marque />} />} />
          <Route
            path="/marque/ajouter"
            element={<AdminHome comp={<MarqueForm stat={1} />} />}
          />
          <Route
            path="/marque/edit"
            element={<AdminHome comp={<MarqueForm stat={2} />} />}
            // <MarqueForm stat={2} />
          />
          <Route
            path="/user/ajouter"
            element={<AdminHome comp={<UserForm stat={1} />} />}
          />
          <Route
            path="/user/edit"
            element={<AdminHome comp={<UserForm stat={2} />} />}
          />
          <Route path="*" element={<P404 />} />{" "}
        </Routes>{" "}
      </BrowserRouter>{" "}
    </>
  );
}

export default App;
