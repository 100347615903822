import CircularProgress from "@mui/material/CircularProgress";
const Loading = () => {
  return (
    <>
      <div className="loading-page">
        <div className="loading-box">
          <CircularProgress />
        </div>
      </div>
    </>
  );
};
export default Loading;
