import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import FormViste from "./FormViste";
import PaiementForm from "./PaiementForm";
import {
  AddClient,
  AddFactureValide,
  AddPaiement,
  AddPaiementViste,
  AddRmise,
  AddTypeAssurance,
  AddTypeVerre,
  getCart,
} from "../Services/CartService";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
export default function StepForm(props) {
  const [value, setValue] = useState(1);
  const [type_verre, settype_verre] = useState("");
  const [type_verre2, settype_verre2] = useState("");
  const [montant, setmontant] = useState(0);
  const [montant2, setmontant2] = useState(0);
  const [remise, setremise] = useState(0);
  const [refreshe, setrefreshe] = useState(false);
  const [ListClient, setListClient] = useState([]);
  const [disableClient, setdisableClient] = useState(false);
  const [ObjectClient, setObjectClient] = useState({
    nom: null,
    prenom: null,
    tele: null,
    id: null,
    client_exist: false,
  });
  const [ObjectPaiment, setObjectPaiment] = useState({
    type_mode: "Avance",
    id: 1,
    montant: 0,
  });
  const select = useSelector((state) => {
    return state;
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    GetClients();
    let a = getCart();

    setObjectPaiment(a.paiement);

    setmontant(a.typeVerrer.montant);
    settype_verre(a.typeVerrer.type);
    setmontant2(a.typeVerrer.montantTow);
    settype_verre2(a.typeVerrer.typeTow);
    setremise(a.remise);
    setObjectClient({
      nom: a.nom,
      prenom: a.prenom,
      tele: a.tele,
      id: a.client,
      client_exist: a.client_exist,
    });
  }, []);
  const GetClients = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "clients", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((res) => {
        setListClient(res.data);
      });
  };
  return (
    <div className="join">
      <div className="delet-dialog">
        <div className="box-delete1">
          <header className="h-panier">
            <div className="t-i-panier">
              <i className="fa-regular fa-address-card"></i> <span>Viste</span>
            </div>
            <div
              className="panier-close"
              onClick={() => {
                props.setviste(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </header>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <TabPanel value={1}>
                <div className="box-delete1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <label>Client Exist ?</label>
                    <input
                      defaultChecked={ObjectClient.client_exist}
                      type="checkbox"
                      onChange={(e) => {
                        setdisableClient(e.target.checked);
                        let a = ObjectClient;
                        a.client_exist = e.target.value;
                        setObjectClient(a);
                        AddClient({
                          nom: a.nom,
                          prenom: a.prenom,
                          tele: a.tele,
                          id: a.id,
                          client_exist: e.target.checked,
                        });
                        setrefreshe(!refreshe);
                      }}
                    ></input>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      disabled={!disableClient}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          let a = ObjectClient;
                          a.nom = newValue.nom;
                          a.prenom = newValue.prenom;
                          a.id = newValue.id;
                          a.tele = newValue.telephone;
                          setObjectClient(a);
                          AddClient({
                            nom: newValue.nom,
                            prenom: newValue.prenom,
                            tele: newValue.telephone,
                            id: newValue.id,
                            client_exist: true,
                          });
                          setrefreshe(!refreshe);
                        }
                      }}
                      options={ListClient}
                      style={{ width: "100%" }}
                      getOptionLabel={(option) =>
                        option.nom + " " + option.prenom
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Clients ..." />
                      )}
                    />
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <label>Nom</label>
                    <input
                      disabled={disableClient}
                      defaultValue={ObjectClient.nom}
                      placeholder="nom"
                      onChange={(e) => {
                        let a = ObjectClient;
                        a.nom = e.target.value;
                        setObjectClient(a);
                        AddClient({
                          nom: a.nom,
                          prenom: a.prenom,
                          tele: a.tele,
                        });
                      }}
                    ></input>
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <label>Prenom</label>
                    <input
                      disabled={disableClient}
                      defaultValue={ObjectClient.prenom}
                      placeholder="prenom"
                      onChange={(e) => {
                        let a = ObjectClient;
                        a.prenom = e.target.value;
                        setObjectClient(a);
                        AddClient({
                          nom: a.nom,
                          prenom: a.prenom,
                          tele: a.tele,
                        });
                      }}
                    ></input>
                  </div>{" "}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>Telephone :</label>
                    <input
                      disabled={disableClient}
                      defaultValue={ObjectClient.tele}
                      placeholder="telephone"
                      onChange={(e) => {
                        let a = ObjectClient;
                        a.tele = e.target.value;
                        setObjectClient(a);
                        AddClient({
                          nom: a.nom,
                          prenom: a.prenom,
                          tele: a.tele,
                        });
                      }}
                    ></input>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel value={2}>
                <FormViste setrefreshe={setrefreshe} refreshe={refreshe} />
              </TabPanel>
              <TabPanel value={3}>
                <div className="box-delete1">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <Autocomplete
                      onChange={(event, newValue) => {
                        settype_verre(newValue.type);
                        AddTypeVerre({
                          type: newValue.type,
                          montant: montant,
                          typeTow: type_verre2,
                          montantTow: montant2,
                        });
                      }}
                      options={[
                        { type: "1.5 blanc" },
                        { type: "1.5Ar" },
                        { type: "1.56blanc " },
                        { type: "1.56Ar" },
                        { type: "1.6 Ar" },
                        { type: "1,67Ar" },
                        { type: "1,74Ar" },
                        { type: "1,56Photogris /blanc" },
                        { type: "1.56photogris/Ar" },
                        { type: "Photogris /bleu" },
                        { type: "1,56 filtre bleu" },
                        { type: "1,6 bleu" },
                        { type: "1,67bleu" },
                        { type: "Progressif blanc" },
                        { type: "Progressif Ar" },
                        { type: "Progressif photogris/blanc" },
                        { type: "Progressif photogris/AR" },
                      ]}
                      style={{ width: "100%" }}
                      getOptionLabel={(option) => option.type}
                      renderInput={(params) => (
                        <TextField {...params} label="Type Verre" />
                      )}
                    />
                  </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <label>Type verre</label>
                      <input
                        defaultValue={type_verre}
                        placeholder="Type verre"
                        onChange={(e) => {
                          settype_verre(e.target.value);
                          AddTypeVerre({
                            type: e.target.value,
                            montant: montant,
                            typeTow: type_verre2,
                            montantTow: montant2,
                          });
                        }}
                      ></input>
                    </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <label>Montant</label>
                    <input
                      defaultValue={montant}
                      placeholder="00"
                      type="number"
                      onChange={(e) => {
                        setmontant(e.target.value);

                        AddTypeVerre({
                          type: type_verre,
                          montant: e.target.value,
                          typeTow: type_verre2,
                          montantTow: montant2,
                        });
                      }}
                    ></input>
                  </div>{" "}
                  {select.viste.id_type_viste == 1 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        <Autocomplete
                          onChange={(event, newValue) => {
                            settype_verre2(newValue.type);
                            AddTypeVerre({
                              type: type_verre,
                              montant: montant,
                              typeTow: newValue.type,
                              montantTow: montant2,
                            });
                            setrefreshe(!refreshe);
                          }}
                          options={[
                            { type: "1.5 blanc" },
                            { type: "1.5Ar" },
                            { type: "1.56blanc " },
                            { type: "1.56Ar" },
                            { type: "1.6 Ar" },
                            { type: "1,67Ar" },
                            { type: "1,74Ar" },
                            { type: "1,56Photogris /blanc" },
                            { type: "1.56photogris/Ar" },
                            { type: "Photogris /bleu" },
                            { type: "1,56 filtre bleu" },
                            { type: "1,6 bleu" },
                            { type: "1,67bleu" },
                            { type: "Progressif blanc" },
                            { type: "Progressif Ar" },
                            { type: "Progressif photogris/blanc" },
                            { type: "Progressif photogris/AR" },
                          ]}
                          style={{ width: "100%" }}
                          getOptionLabel={(option) => option.type}
                          renderInput={(params) => (
                            <TextField {...params} label="Type Verre 2" />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        <label>Type verre 2</label>
                        <input
                          defaultValue={type_verre2}
                          placeholder="Type verre 2"
                          onChange={(e) => {
                            settype_verre(e.target.value);
                            AddTypeVerre({
                              type: type_verre,
                              montant: montant,
                              typeTow: e.target.value,
                              montantTow: montant2,
                            });
                          }}
                        ></input>
                      </div>{" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        <label>Montant 2</label>
                        <input
                          defaultValue={montant2}
                          placeholder="00"
                          type="number"
                          onChange={(e) => {
                            setmontant2(e.target.value);
                            AddTypeVerre({
                              type: type_verre,
                              montant: montant,
                              typeTow: type_verre2,
                              montantTow: e.target.value,
                            });
                          }}
                        ></input>
                      </div>{" "}
                    </>
                  ) : null}
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>Remise : </label>
                    <input
                      defaultValue={remise}
                      placeholder="00"
                      type="number"
                      onChange={(e) => {
                        setremise(e.target.value);
                        AddRmise(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={4}>
                <label>Avance : </label>
                <input
                  defaultValue={ObjectPaiment.montant}
                  onChange={(e) => {
                    let a = ObjectPaiment;
                    a.montant = parseFloat(e.target.value);
                    setObjectPaiment(a);
                    AddPaiementViste(a);
                  }}
                  placeholder="montant"
                  type="number"
                  id="montant"
                  min="0"
                />{" "}
                <label>Type Assurance : </label>
                <input
                  onChange={(e) => {
                    AddTypeAssurance(e.target.value);
                  }}
                  placeholder="Type Asssurance"
                  min="0"
                />
                <input
                  type="checkbox"
                  onChange={(e) => {
                    AddFactureValide(e.target.checked);
                  }}
                />{" "}
                <label>facture</label>
              </TabPanel>
            </TabContext>
          </Box>
          <header className="h-panier">
            <div
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                borderRadius: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                if (value > 1) {
                  handleChange(value - 1);
                }
              }}
            >
              {"< "}Retour
            </div>
            <div
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                borderRadius: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                if (value < 4) {
                  handleChange(value + 1);
                } else {
                  props.setviste(false);
                }
              }}
            >
              Suivant {" >"}
            </div>
          </header>
        </div>
      </div>
    </div>
  );
}
