import React from "react";

export default function UpdateRemise(props) {
  return (
    <div className="join">
      <div className="recptipn-container">
        <article style={{ minWidth: 600 }}>
          <div className="close-menu">
            <div
              className="panier-close"
              onClick={() => {
                props.setvalue(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <center>
            <h4>Modifier Remise</h4>
          </center>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label>Remise : </label>
            <input
              defaultValue={props.remise}
              placeholder="00"
              type="number"
              onChange={(e) => {
                props.setremise(e.target.value);
              }}
            ></input>
          </div>
          <div
            style={{
              padding: 10,
              background: "#009F7F",
              color: "#fff",
              borderRadius: 25,
              cursor: "pointer",
              textAlign: "center",
              marginTop: 20,
            }}
            onClick={() => {
              props.onclick();
            }}
          >
            Modifier
          </div>
        </article>
      </div>
    </div>
  );
}
