import { Container } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import logo from "../assets/logo.png";
import { getCart } from "../Services/CartService";
import { connect, useSelector } from "react-redux";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const select = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    console.log(select);
  }, []);
  return (
    <Container ref={ref} className="background-print">
      {" "}
      <div className="print-head">
        <img
          src={process.env.REACT_APP_IMAGE + select.societe.logo}
          className="logo-print"
        />
        <div className="print-titre">Facture N° {props.panier.n_facture}</div>
        <div className="print-titre">{format(new Date(), "dd/MM/yyyy")}</div>
      </div>
      <div className="info-print">
        <span>Client : {props.panier.nom + " " + props.panier.prenom}</span>
        <span>Telephone : {props.panier.tele}</span>
      </div>
      <table className="print-table" id="customers">
        <thead className="thead-print">
          <th>Produit</th>
          <th>Qte</th>
          <th>Prix</th>
          <th>Total</th>
        </thead>
        <tbody className="tbody-print">
          {props.panier.details.map((row) => (
            <tr>
              <td>{row.desgination}</td>
              <td>{row.qte}</td>
              <td>{row.prix_facture}</td>
              <td>{row.qte * row.prix_facture} DH</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="total-print">
        <span style={{ color: "#26377C" }}>
          Total Net : {props.panier.total_facture} DH
        </span>
      </div>
      <div className="ordenence">
        {/* width: "500px", textAlign: "center" */}
        <span className="facture_detail">
          {select.parametre.facture_footer}
        </span>
      </div>
    </Container>
  );
});

class PrintFacture extends React.PureComponent {
  constructor() {
    super();
    this.state = { token: "" };
    this.AddCommande = this.AddCommande.bind(this);
  }
  async AddCommande() {
    let a = getCart();
    axios
      .post(
        process.env.REACT_APP_API_URL + "commande",
        {
          type_assurance: a.type_assurance,
          valide: a.valide,
          nom: a.nom,
          prenom: a.prenom,
          tele: a.tele,
          items: a.items,
          paiement: a.paiement,
          visite: this.props.viste,
          total_command: parseFloat(a.total_command),
          total_paiement: parseFloat(a.total_paiement),
          rest_a_payer: parseFloat(a.rest_a_payer),
          type_verre: a.typeVerrer,
          remise: parseFloat(a.remise),
          user: this.props.User,
          client_id: a.client,
          client_exist: a.client_exist,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      )
      .then((res) => {});
  }
  async componentDidMount() {}
  render() {
    return (
      <div className="join">
        <div className="delet-dialog">
          <div className="box-delete1">
            <header className="h-panier">
              <div className="t-i-panier">
                <i className="fa-solid fa-pager"></i> <span>Facture</span>
              </div>
              <div
                className="panier-close"
                onClick={() => {
                  this.props.setvalue(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </header>
            <ComponentToPrint
              ref={(el) => (this.componentRef = el)}
              panier={this.props.facture}
              datesearch={this.props.datesearch}
            />
            <span
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactToPrint
                trigger={() => {
                  return (
                    <span className="print-btn" style={{ cursor: "pointer" }}>
                      <i className="fas fa-print"></i> Imprimer
                    </span>
                  );
                }}
                content={() => this.componentRef}
                onAfterPrint={() => {
                  this.AddCommande();
                  this.props.setredirectTOclient(true);
                }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(PrintFacture);
function mapStateToProps(state) {
  return {
    token: state.Token,
    User: state.User,
    viste: state.viste,
  };
}
