import axios from "axios";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const CommandDetail = (props) => {
  const [show, setshow] = useState([]);

  useEffect(() => {
    setshow(
      props.Rece.details.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.desgination}</TableCell>{" "}
          <TableCell align="center">{row.qte}</TableCell>{" "}
          <TableCell align="center">{row.prix}</TableCell>{" "}
        </TableRow>
      ))
    );
  }, []);
  return (
    <>
      <div className="join">
        <div className="recptipn-container">
          <article>
            <div className="close-menu">
              <div
                className="panier-close"
                onClick={() => {
                  props.setvalue(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <center>
              <h4>Detail Commande</h4>
            </center>
            {/* {props.Rece.note} */}
            <div className="rece-header">
              <div className="rec-date">
                Date Facture: {props.Rece.date_facture_vente}
              </div>
              <div className="rec-date">Num : {props.Rece.n_facture}</div>

              <div className="rec-date">
                Client : {props.Rece.nom} {props.Rece.prenom}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell align="center">Produit</TableCell>
                    <TableCell align="center">Qte</TableCell>
                    <TableCell align="center">Prix</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{show}</TableBody>
              </Table>
              <div className="command-total">
                {" "}
                <div className="total-print">
                  <span style={{ color: "#26377C" }}>
                    Total Net : {props.Rece.total} DH
                  </span>
                  <span style={{ color: "#009F7F" }}>
                    Avance : {parseFloat(props.Rece.avance)} DH
                  </span>
                  <span style={{ color: "#DC2626" }}>
                    Remise : - {props.Rece.remise}
                    DH
                  </span>{" "}
                  <span style={{ color: "#DC2626" }}>
                    Total a payer : {props.Rece.rest_a_payer}
                    DH
                  </span>
                </div>
              </div>
              {props.Rece.prescripteur[0] != undefined ? (
                <div className="print-sinature">
                  <h4>Mesure</h4>
                  <span>
                    Type Viste{" "}
                    {props.Rece.prescripteur[0].id_type_verre == 1 ? (
                      "séparé"
                    ) : (
                      <>
                        {props.Rece.prescripteur[0].id_type_verre == 2
                          ? "VL"
                          : "VP"}
                      </>
                    )}
                  </span>

                  {props.Rece.prescripteur[0].id_type_verre == 1 ? (
                    <>
                      {" "}
                      <div className="ordenence">
                        <span>
                          <span style={{ color: "#26377C", fontWeight: 800 }}>
                            Loin OD
                          </span>{" "}
                          : Sph {props.Rece.prescripteur[0].loin_od_sph} Cyl{" "}
                          {props.Rece.prescripteur[0].loin_od_cyl} Axe{" "}
                          {props.Rece.prescripteur[0].loin_od_axe} Add{" "}
                          {props.Rece.prescripteur[0].loin_od_add}
                        </span>
                        <span>
                          <span style={{ color: "#26377C", fontWeight: 800 }}>
                            Loin OG
                          </span>{" "}
                          : Sph {props.Rece.prescripteur[0].loin_og_sph} Cyl{" "}
                          {props.Rece.prescripteur[0].loin_og_cyl} Axe{" "}
                          {props.Rece.prescripteur[0].loin_og_axe} Add{" "}
                          {props.Rece.prescripteur[0].loin_og_axe}
                        </span>
                        <span>Eip : {props.Rece.prescripteur[0].od_ecart}</span>
                        <span>H : {props.Rece.prescripteur[0].od_ht}</span>
                      </div>{" "}
                      <div className="ordenence">
                        <span>
                          <span style={{ color: "#26377C", fontWeight: 800 }}>
                            Pres OD
                          </span>{" "}
                          : Sph {props.Rece.prescripteur[0].pres_od_sph} Cyl{" "}
                          {props.Rece.prescripteur[0].pres_od_cyl} Axe{" "}
                          {props.Rece.prescripteur[0].pres_od_axe}Add{" "}
                          {props.Rece.prescripteur[0].pres_od_add}
                        </span>
                        <span>
                          <span style={{ color: "#26377C", fontWeight: 800 }}>
                            Pres OG
                          </span>{" "}
                          : Sph {props.Rece.prescripteur[0].pres_og_sph} Cyl{" "}
                          {props.Rece.prescripteur[0].pres_og_cyl} Axe{" "}
                          {props.Rece.prescripteur[0].pres_og_axe} Add{" "}
                          {props.Rece.prescripteur[0].pres_og_add}
                        </span>
                        <span>Eip : {props.Rece.prescripteur[0].og_ecart}</span>
                        <span>H : {props.Rece.prescripteur[0].og_ht}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {props.Rece.prescripteur[0].id_type_verre == 2 ? (
                        <>
                          {" "}
                          <div className="ordenence">
                            <span>
                              <span
                                style={{ color: "#26377C", fontWeight: 800 }}
                              >
                                Loin OD
                              </span>{" "}
                              : Sph {props.Rece.prescripteur[0].loin_od_sph} Cyl{" "}
                              {props.Rece.prescripteur[0].loin_od_cyl} Axe{" "}
                              {props.Rece.prescripteur[0].loin_od_axe}
                              Add {props.Rece.prescripteur[0].loin_od_add}
                            </span>
                            <span>
                              <span
                                style={{ color: "#26377C", fontWeight: 800 }}
                              >
                                Loin OG
                              </span>{" "}
                              : Sph {props.Rece.prescripteur[0].loin_og_sph} Cyl{" "}
                              {props.Rece.prescripteur[0].loin_og_cyl} Axe{" "}
                              {props.Rece.prescripteur[0].loin_og_axe}
                            </span>
                            <span>
                              Eip : {props.Rece.prescripteur[0].od_ecart}
                            </span>
                            <span>H : {props.Rece.prescripteur[0].od_ht}</span>
                          </div>{" "}
                        </>
                      ) : (
                        <>
                          <div className="ordenence">
                            <span>
                              <span
                                style={{ color: "#26377C", fontWeight: 800 }}
                              >
                                Pres OD
                              </span>{" "}
                              : Sph {props.Rece.prescripteur[0].pres_od_sph} Cyl{" "}
                              {props.Rece.prescripteur[0].pres_od_cyl} Axe{" "}
                              {props.Rece.prescripteur[0].pres_od_axe}
                            </span>
                            <span>
                              <span
                                style={{ color: "#26377C", fontWeight: 800 }}
                              >
                                Pres OG
                              </span>{" "}
                              : Sph {props.Rece.prescripteur[0].pres_og_sph} Cyl{" "}
                              {props.Rece.prescripteur[0].pres_og_cyl} Axe{" "}
                              {props.Rece.prescripteur[0].pres_og_axe}
                            </span>
                            <span>
                              Eip : {props.Rece.prescripteur[0].og_ecart}
                            </span>
                            <span>H : {props.Rece.prescripteur[0].og_ht}</span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : null}
            </TableContainer>
          </article>
        </div>
      </div>
    </>
  );
};
export default CommandDetail;
