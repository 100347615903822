import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Loading from "../loading/Loading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
const ReceptionForm = (props) => {
  const [Loading1, setLoading1] = useState(false);
  const navigate = useNavigate();

  //
  const [Rederict1, setRederict] = useState(false);
  //Varib Category
  const [date, setdate] = useState(null);
  const [note, setnote] = useState(null);
  const [n_facture, setn_facture] = useState(null);
  const [qte, setqte] = useState(1);
  const [boxq, setboxq] = useState(1);
  const [Error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [fournisseurselect, setfournisseurselect] = useState({
    id: null,
  });
  const [ArticleSelected, setArticleSelected] = useState(null);
  const [ListArticle, setListArticle] = useState([]);
  const [ListFournisseur, setListFournisseur] = useState([]);
  const [ListDetailReception, setListDetailReception] = useState([]);
  let inputRef;
  let index;
  const select = useSelector((state) => {
    return state;
  });
  const HanldAddDetail = () => {
    index = ListDetailReception.findIndex((object) => {
      return object.id === ArticleSelected.id;
    });

    if (index === -1) {
      const newTodos = [...ListDetailReception];
      newTodos.push({
        produit: ArticleSelected.desgination,
        reference: ArticleSelected.reference,
        qte: qte * boxq,
        id: ArticleSelected.id,
      });
      setListDetailReception(newTodos);
    }
    inputRef.value = "";
    inputRef.focus();
  };
  const HandleDeletDetail = (e) => {
    const newTodos = [...ListDetailReception];
    newTodos.splice(e.target.dataset.index, 1);
    setListDetailReception(newTodos);
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      HanldAddDetail();
    }
  };
  const fetchData = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "articles", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((resp) => {
        setListArticle(resp.data);
        setLoading1(false);
      });
  };
  const getFournisseur = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "fournisseurs", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((resp) => {
        setListFournisseur(resp.data);
        setLoading1(false);
      });
  };
  useEffect(() => {
    fetchData();
    getFournisseur();
  }, []);
  const HandleChange = (e) => {
    switch (e.target.id) {
      case "note":
        setnote(e.target.value);
        break;
      case "date":
        setdate(e.target.value);
        break;
      case "qte_box":
        setboxq(e.target.value);
        break;
      case "qte":
        setqte(e.target.value);
        break;
      case "n_reception":
        setn_facture(e.target.value);
        break;
    }
  };
  const HandleClick = async () => {
    console.log(fournisseurselect);
    if (
      fournisseurselect.id == null ||
      fournisseurselect == null ||
      ListDetailReception.length < 1
    ) {
      setOpen(true);
      setError("Veuillez sélectionner le fournisseur ou ajouter un produit");
      console.log("dsfsdfsd");
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + "achat",
          {
            items: ListDetailReception,
            user: select.User,
            id_fournisseur: fournisseurselect.id,
            note: note,
            n_facture: n_facture,
          },
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
            },
          }
        )
        .then((res) => {
          navigate("/achat");
        });
    }
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  return (
    <>
      {Rederict1 == true ? <Navigate to="/admin/reception" /> : null}
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {Error}
            </Alert>
          </Snackbar>
          <div className="group-titre">Ajouter Reception</div>

          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                Ajouter la Note de votre Reception et les informations
                nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Date Reception</label>
                <input
                  defaultValue={date}
                  type="date"
                  id="date"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Note</label>
                <input
                  defaultValue={note}
                  type="text"
                  id="note"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>N°Reception</label>
                <input
                  defaultValue={n_facture}
                  type="text"
                  id="n_reception"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <Autocomplete
                  onKeyPress={handleKeypress}
                  id="grouped-demo"
                  openOnFocus
                  onChange={(event, newValue) => {
                    setfournisseurselect(newValue);
                  }}
                  options={ListFournisseur}
                  getOptionLabel={(option) => option.societe}
                  renderInput={(params) => (
                    <TextField {...params} label="Fournisseur" />
                  )}
                />
              </div>{" "}
            </div>
          </div>
          <div className="reception-bar-add">
            <div className="input-form">
              {" "}
              {/* <input ></input> */}
              <Autocomplete
                onKeyPress={handleKeypress}
                id="grouped-demo"
                openOnFocus
                onChange={(event, newValue) => {
                  setArticleSelected(newValue);
                }}
                options={ListArticle}
                getOptionLabel={(option) =>
                  option.reference + " " + option.desgination
                }
                sx={{ minWidth: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Produits"
                    onKeyPress={handleKeypress}
                    inputRef={(input) => {
                      inputRef = input;
                    }}
                  />
                )}
              />
            </div>{" "}
            <div className="input-form">
              {" "}
              <input
                type="number"
                id="qte"
                placeholder="Qauntite"
                onKeyPress={handleKeypress}
                defaultValue={qte}
                onChange={HandleChange}
              ></input>
            </div>{" "}
            <div className="input-form">
              {" "}
              <input
                type="number"
                id="qte_box"
                placeholder="Numbre boîte"
                onKeyPress={handleKeypress}
                defaultValue={boxq}
                onChange={HandleChange}
              ></input>
            </div>
            <div className="input-form flex">
              <button className="btn-g-add" onClick={HanldAddDetail}>
                +Ajouter
              </button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className="t-header">
                <TableRow>
                  <TableCell>Produit</TableCell>
                  <TableCell align="center">Qte</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ListDetailReception.map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {row.produit} {row.reference}
                      </TableCell>
                      <TableCell align="center">{row.qte}</TableCell>

                      <TableCell align="center">
                        <div className="group-action">
                          <a className="action-delet">
                            <i
                              className="fa-solid fa-trash-can"
                              onClick={HandleDeletDetail}
                              data-index={index}
                            ></i>
                          </a>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="group-button">
            <button className="group-b-b">
              <Link to="/admin/reception">Arrière</Link>
            </button>
            <button className="btn-g-add" onClick={HandleClick}>
              Crée
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default ReceptionForm;
