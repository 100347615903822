const initialState = {
  d_admin: false,
  id_group: null,
  id_cate_p: null,
  id_cate_s: null,
  ListPanier: null,
  d_user: null,
  User: null,
  Token: null,
  societe: null,
  parametre: null,
  viste: {
    loin_od_sph: null,
    loin_od_cyl: null,
    loin_od_axe: null,
    loin_og_sph: null,
    loin_og_cyl: null,
    loin_og_axe: null,
    od_ecart: null,
    od_ht: null,
    pres_od_sph: null,
    pres_od_cyl: null,
    pres_od_axe: null,

    pres_og_sph: null,
    pres_og_cyl: null,
    pres_og_axe: null,

    og_ecart: null,
    og_ht: null,
    id_type_viste: 1,
    loin_od_add: null,
    loin_og_add: null,
    pres_od_add: null,
    pres_og_add: null,
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTION_GROUP_CHANGE": {
      return {
        ...state,
        id_group: action.id_group,
      };
    }
    case "ACTION_CATE_P_CHANGE": {
      return {
        ...state,

        id_cate_p: action.id_cate_p,
      };
    }
    case "ACTION_GROUP_CAT_S_CHANGE": {
      return {
        ...state,
        id_cate_s: action.id_cate_s,
      };
    }
    case "ACTION_PANIER": {
      return {
        ...state,
        ListPanier: action.ListPanier,
      };
    }
    case "ACTION_LOGIN": {
      return {
        ...state,
        User: action.User,
        Token: action.Token,
        societe: action.societe,
        parametre: action.parametre,
      };
    }
    case "ACTION_VISTE": {
      return {
        ...state,
        viste: action.viste,
      };
    }
    default:
      return state;
  }
};

export default reducer;
