import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FournisseurForm = (props) => {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(true);
  //
  //Varib Group
  const navigate = useNavigate();

  const [ObjectClient, setObjectClient] = useState({
    societe: null,
    nom: null,
    Pprenom: null,
    adresse: null,
    ville: null,
    tele: null,
    mobile: null,
    email: null,
    observations: null,
  });

  useEffect(() => {
  
    if (props.stat == 2) {
      setObjectClient({
        societe: location.state.client.societe,
        nom: location.state.client.nom,
        Pprenom: location.state.client.Pprenom,
        adresse: location.state.client.adresse,
        ville: location.state.client.ville,
        tele: location.state.client.tele,
        mobile: location.state.client.mobile,
        email: location.state.client.email,
        observations: location.state.client.observations,
      });

      setLoading1(false);
    } else {
      setLoading1(false);
    }
  }, []);
  const HandleChange = (e) => {
    let a = ObjectClient;
    switch (e.target.id) {
      case "societe":
        a.societe = e.target.value;
        setObjectClient(a);
        break;
      case "nom":
        a.nom = e.target.value;
        setObjectClient(a);
        break;
      case "Pprenom":
        a.Pprenom = e.target.value;
        setObjectClient(a);
        break;
      case "adresse":
        a.adresse = e.target.value;
        setObjectClient(a);
        break;
      case "ville":
        a.ville = e.target.value;
        setObjectClient(a);
        break;
      case "tele":
        a.tele = e.target.value;
        setObjectClient(a);
        break;
      case "mobile":
        a.mobile = e.target.value;
        setObjectClient(a);
        break;
      case "email":
        a.email = e.target.value;
        setObjectClient(a);
        break;
      case "observations":
        a.observations = e.target.value;
        setObjectClient(a);
        break;
    }
  };
  const HandleClick = async () => {
    // 1 add 2 update
    if (props.stat == 1) {
      const formData = new FormData();

      if (ObjectClient.societe !== null) {
        formData.append("societe", ObjectClient.societe);
      }
      if (ObjectClient.nom !== null) {
        formData.append("nom", ObjectClient.nom);
      }
      if (ObjectClient.Pprenom !== null) {
        formData.append("Pprenom", ObjectClient.Pprenom);
      }
      if (ObjectClient.adresse !== null) {
        formData.append("adresse", ObjectClient.adresse);
      }
      if (ObjectClient.email !== null) {
        formData.append("email", ObjectClient.email);
      }
      if (ObjectClient.mobile !== null) {
        formData.append("mobile", ObjectClient.mobile);
      }
      if (ObjectClient.observations !== null) {
        formData.append("observations", ObjectClient.observations);
      }
      if (ObjectClient.tele !== null) {
        formData.append("tele", ObjectClient.tele);
      }
      if (ObjectClient.ville !== null) {
        formData.append("ville", ObjectClient.ville);
      }

      await axios
        .post(process.env.REACT_APP_API_URL + "create_fournisseur", formData, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          navigate(-1);
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_API_URL +
            "edit_fournisseur/" +
            location.state.client.id,
          {
            societe: ObjectClient.societe,
            nom: ObjectClient.nom,
            Pprenom: ObjectClient.Pprenom,
            adresse: ObjectClient.adresse,
            ville: ObjectClient.ville,
            tele: ObjectClient.tele,
            mobile: ObjectClient.mobile,
            email: ObjectClient.email,
            observations: ObjectClient.observations,
          },
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
            },
          }
        )
        .then((resp) => {
          navigate(-1);
        });
    }
  };
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">
            {props.stat == 1 ? "Ajouter" : "Modifier"} Fournisseur
          </div>
          {/* <form className="form-group"> */}
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajoute" : "Modifiez"} le nom de votre
                Fournisseur et les informations nécessaires à partir d'ici
              </p>
            </div>

            <div className="g-g-m-content">
              <div className="input-form">
                <label>Sociéte</label>
                <input
                  defaultValue={ObjectClient.societe}
                  type="text"
                  id="societe"
                  onChange={HandleChange}
                  placeholder="Sociéte ..."
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Nom</label>
                <input
                  defaultValue={ObjectClient.nom}
                  type="text"
                  id="nom"
                  placeholder="Nom ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Prenom</label>
                <input
                  defaultValue={ObjectClient.Pprenom}
                  type="text"
                  id="Pprenom"
                  placeholder="Prenom ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Adresse</label>
                <input
                  defaultValue={ObjectClient.adresse}
                  type="text"
                  id="adresse"
                  placeholder="Rue ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Ville</label>
                <input
                  defaultValue={ObjectClient.ville}
                  type="tel"
                  id="ville"
                  placeholder="Ville ...    "
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Téléphone</label>
                <input
                  defaultValue={ObjectClient.tele}
                  type="tel"
                  id="tele"
                  placeholder="Ville .."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Mobile</label>
                <input
                  defaultValue={ObjectClient.mobile}
                  type="tel"
                  id="mobile"
                  placeholder="060000000"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Email</label>
                <input
                  defaultValue={ObjectClient.email}
                  type="email"
                  id="email"
                  placeholder="Example@example.com"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Observations</label>
                <textarea
                  defaultValue={ObjectClient.observations}
                  id="observations"
                  placeholder="Observations ..."
                  onChange={HandleChange}
                ></textarea>
              </div>{" "}
              {/* <label htmlFor="file-upload2" className="custom-file-upload">
                Télécharger Image
              </label>
              <input
                id="file-upload2"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={OnchangeImage2}
              />
              <img className="img-show" src={s2}></img> */}
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleClick}>
              {props.stat == 1 ? "Crée" : "Modifée"}
            </button>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
};
export default FournisseurForm;
