import React, { useEffect, useState } from "react";
import { AddViste, getCart } from "../Services/CartService";
import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";

export default function FormViste(props) {
  const [selectedValue, setSelectedValue] = React.useState(1);
  const select = useSelector((state) => {
    return state;
  });
  const dispatch = useDispatch();
  const [ObjectViste, setObjectViste] = useState({
    loin_od_sph: null,
    loin_od_cyl: null,
    loin_od_axe: null,
    loin_og_sph: null,
    loin_og_cyl: null,
    loin_og_axe: null,
    od_ecart: null,
    od_ht: null,
    pres_od_sph: null,
    pres_od_cyl: null,
    pres_od_axe: null,

    pres_og_sph: null,
    pres_og_cyl: null,
    pres_og_axe: null,

    og_ecart: null,
    og_ht: null,
    id_type_viste: 1,
    loin_od_add: null,
    loin_og_add: null,
    pres_od_add: null,
    pres_og_add: null,
  });
  const HandleChange = (e) => {
    let a = ObjectViste;
    switch (e.target.id) {
      case "viste":
        a.id_type_viste = e.target.value;
        setObjectViste(a);
        break;
      case "loin_od_sph":
        a.loin_od_sph = e.target.value;
        setObjectViste(a);
        break;
      case "loin_od_cyl":
        a.loin_od_cyl = e.target.value;
        setObjectViste(a);
        break;
      case "loin_od_axe":
        a.loin_od_axe = e.target.value;
        setObjectViste(a);
        break;

      case "loin_og_sph":
        a.loin_og_sph = e.target.value;
        setObjectViste(a);
        break;
      case "loin_og_cyl":
        a.loin_og_cyl = e.target.value;
        setObjectViste(a);
        break;
      case "loin_og_axe":
        a.loin_og_axe = e.target.value;
        setObjectViste(a);
        break;
      case "od_ecart":
        a.od_ecart = e.target.value;
        setObjectViste(a);
        break;
      case "od_ht":
        a.od_ht = e.target.value;
        setObjectViste(a);
        break;
      case "pres_od_sph":
        a.pres_od_sph = e.target.value;
        setObjectViste(a);
        break;
      case "pres_og_sph":
        a.pres_og_sph = e.target.value;
        setObjectViste(a);
        break;
      case "pres_od_cyl":
        a.pres_od_cyl = e.target.value;
        setObjectViste(a);
        break;
      case "pres_og_cyl":
        a.pres_og_cyl = e.target.value;
        setObjectViste(a);
        break;
      case "pres_od_axe":
        a.pres_od_axe = e.target.value;
        setObjectViste(a);
        break;
      case "pres_og_axe":
        a.pres_og_axe = e.target.value;
        setObjectViste(a);
        break;
      case "og_ecart":
        a.og_ecart = e.target.value;
        setObjectViste(a);
        break;
      case "og_ht":
        a.og_ht = e.target.value;
        setObjectViste(a);
        break;
      // loin_od_add: null,
      // loin_og_add: null,
      // pres_od_add: null,
      // pres_og_add: null,
      case "loin_od_add":
        a.loin_od_add = e.target.value;
        setObjectViste(a);
        break;
      case "loin_og_add":
        a.loin_og_add = e.target.value;
        setObjectViste(a);
        break;
      case "pres_od_add":
        a.pres_od_add = e.target.value;
        setObjectViste(a);
        break;
      case "pres_og_add":
        a.pres_og_add = e.target.value;
        setObjectViste(a);
        break;
    }
    if (a.loin_od_add == undefined) {
      a.loin_od_add = null;
    }
    if (a.loin_og_add == undefined) {
      a.loin_og_add = null;
    }
    if (a.pres_od_add == undefined) {
      a.pres_od_add = null;
    }
    if (a.pres_og_add == undefined) {
      a.pres_og_add = null;
    }
    dispatch({
      type: "ACTION_VISTE",
      viste: {
        id_type_viste: a.id_type_viste,
        loin_od_sph: a.loin_od_sph,
        loin_od_cyl: a.loin_od_cyl,
        loin_od_axe: a.loin_od_axe,
        loin_og_sph: a.loin_og_sph,
        loin_og_cyl: a.loin_og_cyl,
        loin_og_axe: a.loin_og_axe,
        od_ecart: a.od_ecart,
        od_ht: a.od_ht,
        pres_od_sph: a.pres_od_sph,
        pres_od_cyl: a.pres_od_cyl,
        pres_od_axe: a.pres_od_axe,
        pres_og_sph: a.pres_og_sph,
        pres_og_cyl: a.pres_og_cyl,
        pres_og_axe: a.pres_og_axe,
        og_ecart: a.og_ecart,
        og_ht: a.og_ht,
        loin_od_add: a.loin_od_add,
        loin_og_add: a.loin_og_add,
        pres_od_add: a.pres_od_add,
        pres_og_add: a.pres_og_add,
      },
    });
    AddViste({
      id_type_viste: a.id_type_viste,
      loin_od_sph: a.loin_od_sph,
      loin_od_cyl: a.loin_od_cyl,
      loin_od_axe: a.loin_od_axe,
      loin_og_sph: a.loin_og_sph,
      loin_og_cyl: a.loin_og_cyl,
      loin_og_axe: a.loin_og_axe,
      od_ecart: a.od_ecart,
      od_ht: a.od_ht,
      pres_od_sph: a.pres_od_sph,
      pres_od_cyl: a.pres_od_cyl,
      pres_od_axe: a.pres_od_axe,
      pres_og_sph: a.pres_og_sph,
      pres_og_cyl: a.pres_og_cyl,
      pres_og_axe: a.pres_og_axe,
      og_ecart: a.og_ecart,
      og_ht: a.og_ht,
      loin_od_add: a.loin_od_add,
      loin_og_add: a.loin_og_add,
      pres_od_add: a.pres_od_add,
      pres_og_add: a.pres_og_add,
    });
    props.setrefreshe(!props.refreshe);
  };
  useEffect(() => {
    let a = getCart();
    setObjectViste(a.visite);
    dispatch({
      type: "ACTION_VISTE",
      viste: a.visite,
    });
    setSelectedValue(a.visite.id_type_viste);
  }, []);
  useEffect(() => {
    let a = getCart();
    setObjectViste(a.visite);
  }, [props.refreshe]);
  return (
    <>
      {/* Loins */}
      {/* 1 = séparé   2 = VL   p = VP  */}
      <div>
        {" "}
        <Radio
          id="viste"
          checked={selectedValue == 1}
          onChange={(event) => {
            setSelectedValue(event.target.value);
            HandleChange(event);
          }}
          value={1}
          name="radio-buttons"
        />
        <label>séparé </label>
        <Radio
          id="viste"
          checked={selectedValue == 2}
          onChange={(event) => {
            setSelectedValue(event.target.value);
            HandleChange(event);
          }}
          value={2}
          name="radio-buttons"
        />{" "}
        <label>VL </label>
        <Radio
          id="viste"
          checked={selectedValue == 3}
          onChange={(event) => {
            setSelectedValue(event.target.value);
            HandleChange(event);
          }}
          value={3}
          name="radio-buttons"
        />
        <label>VP </label>
      </div>
      {/* LOIN */}
      {selectedValue == 1 ? (
        <>
          <div className="minform">
            <div className="group_min">
              <label
                style={{
                  color: "#26377C",
                  fontWeight: "900",
                }}
              >
                Loin :{" "}
              </label>{" "}
              <label>Sph</label>
              <label>Cyl</label>
              <label>Axe</label>
              <label>ADD</label>
            </div>
            <div className="group_min">
              <label>OD : </label>
              <input
                defaultValue={ObjectViste.loin_od_sph}
                onChange={HandleChange}
                placeholder="Sph"
                id="loin_od_sph"
              />
              <input
                defaultValue={ObjectViste.loin_od_cyl}
                onChange={HandleChange}
                placeholder="Cyl"
                id="loin_od_cyl"
              />
              <input
                defaultValue={ObjectViste.loin_od_axe}
                onChange={HandleChange}
                placeholder="Axe"
                id="loin_od_axe"
              />{" "}
              <input
                defaultValue={ObjectViste.loin_od_add}
                onChange={HandleChange}
                placeholder="Add"
                id="loin_od_add"
              />
            </div>{" "}
            <div className="group_min">
              <label>OG : </label>
              <input
                defaultValue={ObjectViste.loin_og_sph}
                onChange={HandleChange}
                placeholder="Sph"
                id="loin_og_sph"
              />
              <input
                defaultValue={ObjectViste.loin_og_cyl}
                onChange={HandleChange}
                placeholder="Cyl"
                id="loin_og_cyl"
              />
              <input
                defaultValue={ObjectViste.loin_og_axe}
                onChange={HandleChange}
                placeholder="Axe"
                id="loin_og_axe"
              />
              <input
                defaultValue={ObjectViste.loin_og_add}
                onChange={HandleChange}
                placeholder="Add"
                id="loin_og_add"
              />
            </div>{" "}
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <input
                defaultValue={ObjectViste.od_ecart}
                onChange={HandleChange}
                placeholder="Eip"
                id="od_ecart"
              />
              <input
                defaultValue={ObjectViste.od_ht}
                onChange={HandleChange}
                placeholder="H"
                id="od_ht"
              />
            </div>
          </div>
          {/* Pres */}
          <div className="minform">
            <div className="group_min">
              <label
                style={{
                  color: "#26377C",
                  fontWeight: "900",
                }}
              >
                Pres :{" "}
              </label>{" "}
              <label>Sph</label>
              <label>Cyl</label>
              <label>Axe</label>
              <label>Add</label>
            </div>
            <div className="group_min">
              <label>OD : </label>
              <input
                defaultValue={ObjectViste.pres_od_sph}
                onChange={HandleChange}
                placeholder="Sph"
                id="pres_od_sph"
              />
              <input
                defaultValue={ObjectViste.pres_od_cyl}
                onChange={HandleChange}
                placeholder="Cyl"
                id="pres_od_cyl"
              />
              <input
                defaultValue={ObjectViste.pres_od_axe}
                onChange={HandleChange}
                placeholder="Axe"
                id="pres_od_axe"
              />
              <input
                defaultValue={ObjectViste.pres_od_add}
                onChange={HandleChange}
                placeholder="Add"
                id="pres_od_add"
              />
            </div>{" "}
            <div className="group_min">
              <label>OG : </label>
              <input
                defaultValue={ObjectViste.pres_og_sph}
                onChange={HandleChange}
                placeholder="Sph"
                id="pres_og_sph"
              />
              <input
                defaultValue={ObjectViste.pres_og_cyl}
                onChange={HandleChange}
                placeholder="Cyl"
                id="pres_og_cyl"
              />
              <input
                defaultValue={ObjectViste.pres_og_axe}
                onChange={HandleChange}
                placeholder="Axe"
                id="pres_og_axe"
              />
              <input
                defaultValue={ObjectViste.pres_og_add}
                onChange={HandleChange}
                placeholder="Add"
                id="pres_og_add"
              />
            </div>{" "}
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <input
                defaultValue={ObjectViste.og_ecart}
                onChange={HandleChange}
                placeholder="Eip"
                id="og_ecart"
              />
              <input
                defaultValue={ObjectViste.og_ht}
                onChange={HandleChange}
                placeholder="H"
                id="og_ht"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {selectedValue == 2 ? (
            <>
              {" "}
              <div className="minform">
                <div className="group_min">
                  <label
                    style={{
                      color: "#26377C",
                      fontWeight: "900",
                    }}
                  >
                    Loin :{" "}
                  </label>{" "}
                  <label>Sph</label>
                  <label>Cyl</label>
                  <label>Axe</label>
                  <label>ADD</label>
                </div>
                <div className="group_min">
                  <label>OD : </label>
                  <input
                    defaultValue={ObjectViste.loin_od_sph}
                    onChange={HandleChange}
                    placeholder="Sph"
                    id="loin_od_sph"
                  />
                  <input
                    defaultValue={ObjectViste.loin_od_cyl}
                    onChange={HandleChange}
                    placeholder="Cyl"
                    id="loin_od_cyl"
                  />
                  <input
                    defaultValue={ObjectViste.loin_od_axe}
                    onChange={HandleChange}
                    placeholder="Axe"
                    id="loin_od_axe"
                  />{" "}
                  <input
                    defaultValue={ObjectViste.loin_od_add}
                    onChange={HandleChange}
                    placeholder="Add"
                    id="loin_od_add"
                  />
                </div>{" "}
                <div className="group_min">
                  <label>OG : </label>
                  <input
                    defaultValue={ObjectViste.loin_og_sph}
                    onChange={HandleChange}
                    placeholder="Sph"
                    id="loin_og_sph"
                  />
                  <input
                    defaultValue={ObjectViste.loin_og_cyl}
                    onChange={HandleChange}
                    placeholder="Cyl"
                    id="loin_og_cyl"
                  />
                  <input
                    defaultValue={ObjectViste.loin_og_axe}
                    onChange={HandleChange}
                    placeholder="Axe"
                    id="loin_og_axe"
                  />
                </div>{" "}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <input
                    defaultValue={ObjectViste.od_ecart}
                    onChange={HandleChange}
                    placeholder="Eip"
                    id="od_ecart"
                  />
                  <input
                    defaultValue={ObjectViste.od_ht}
                    onChange={HandleChange}
                    placeholder="H"
                    id="od_ht"
                  />
                </div>
              </div>
            </>
          ) : null}
          {selectedValue == 3 ? (
            <>
              {" "}
              <div className="minform">
                <div className="group_min">
                  <label
                    style={{
                      color: "#26377C",
                      fontWeight: "900",
                    }}
                  >
                    Pres :{" "}
                  </label>{" "}
                  <label>Sph</label>
                  <label>Cyl</label>
                  <label>Axe</label>
                </div>
                <div className="group_min">
                  <label>OD : </label>
                  <input
                    defaultValue={ObjectViste.pres_od_sph}
                    onChange={HandleChange}
                    placeholder="Sph"
                    id="pres_od_sph"
                  />
                  <input
                    defaultValue={ObjectViste.pres_od_cyl}
                    onChange={HandleChange}
                    placeholder="Cyl"
                    id="pres_od_cyl"
                  />
                  <input
                    defaultValue={ObjectViste.pres_od_axe}
                    onChange={HandleChange}
                    placeholder="Axe"
                    id="pres_od_axe"
                  />
                </div>{" "}
                <div className="group_min">
                  <label>OG : </label>
                  <input
                    defaultValue={ObjectViste.pres_og_sph}
                    onChange={HandleChange}
                    placeholder="Sph"
                    id="pres_og_sph"
                  />
                  <input
                    defaultValue={ObjectViste.pres_og_cyl}
                    onChange={HandleChange}
                    placeholder="Cyl"
                    id="pres_og_cyl"
                  />
                  <input
                    defaultValue={ObjectViste.pres_og_axe}
                    onChange={HandleChange}
                    placeholder="Axe"
                    id="pres_og_axe"
                  />
                </div>{" "}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <input
                    defaultValue={ObjectViste.og_ecart}
                    onChange={HandleChange}
                    placeholder="Eip"
                    id="og_ecart"
                  />
                  <input
                    defaultValue={ObjectViste.og_ht}
                    onChange={HandleChange}
                    placeholder="H"
                    id="og_ht"
                  />
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
}
