import axios from "axios";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Loading from "../loading/Loading";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ClientForm = (props) => {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(true);
  //
  //Varib Group
  const navigate = useNavigate();

  const [ObjectClient, setObjectClient] = useState({
    cin: null,
    nom: null,
    prenom: null,
    adresse: null,
    telephone: null,
    ville: null,
    email: null,
    sexe: null,
  });

  useEffect(() => {
    if (props.stat == 2) {
      setObjectClient({
        cin: location.state.client.cin,
        nom: location.state.client.nom,
        prenom: location.state.client.prenom,
        adresse: location.state.client.adresse,
        telephone: location.state.client.telephone,
        ville: location.state.client.ville,
        email: location.state.client.email,
        sexe: location.state.client.sexe,
      });

      setLoading1(false);
    } else {
      setLoading1(false);
    }
  }, []);
  const HandleChange = (e) => {
   
    let a = ObjectClient;
    switch (e.target.id) {
      case "CIN":
        a.cin = e.target.value;
        setObjectClient(a);
        break;
      case "Nom":
        a.nom = e.target.value;
        setObjectClient(a);
        break;
      case "Prenom":
        a.prenom = e.target.value;
        setObjectClient(a);
        break;
      case "Adresse":
        a.adresse = e.target.value;
        setObjectClient(a);
        break;
      case "Téléphone":
        a.telephone = e.target.value;
        setObjectClient(a);
        break;
      case "Ville":
        a.ville = e.target.value;
        setObjectClient(a);
        break;
      case "Email":
        a.email = e.target.value;
        setObjectClient(a);
        break;
      case "sexe":
        a.sexe = e.target.value;
        setObjectClient(a);
        break;
    }
  };
  const HandleClick = async () => {
    // 1 add 2 update
    if (props.stat == 1) {
      const formData = new FormData();

      if (ObjectClient.nom !== null) {
        formData.append("nom", ObjectClient.nom);
      }
      if (ObjectClient.prenom !== null) {
        formData.append("prenom", ObjectClient.prenom);
      }
      if (ObjectClient.cin !== null) {
        formData.append("cin", ObjectClient.cin);
      }
      if (ObjectClient.telephone !== null) {
        formData.append("telephone", ObjectClient.telephone);
      }
      if (ObjectClient.adresse !== null) {
        formData.append("adresse", ObjectClient.adresse);
      }
      if (ObjectClient.sexe !== null) {
        formData.append("sexe", ObjectClient.sexe);
      }
      if (ObjectClient.ville !== null) {
        formData.append("ville", ObjectClient.ville);
      }
      if (ObjectClient.email !== null) {
        formData.append("email", ObjectClient.email);
      }

      // if (image !== null) {
      //   formData.append("image", image);
      // }
      // await axios.post({
      //   method: "post",
      //   url: process.env.REACT_APP_API_KEY + "user_create",
      //   data: formData,
      //   headers: { "Content-Type": "multipart/form-data" },
      // })
      await axios
        .post(process.env.REACT_APP_API_URL + "create_client", formData, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          navigate(-1);
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_API_URL +
            "edit_client/" +
            location.state.client.id,
          {
            cin: ObjectClient.cin,
            nom: ObjectClient.nom,
            prenom: ObjectClient.prenom,
            adresse: ObjectClient.adresse,
            telephone: ObjectClient.telephone,
            ville: ObjectClient.ville,
            email: ObjectClient.email,
            sexe: ObjectClient.sexe,
          },
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          navigate(-1);
        });
    }
  };
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">
            {props.stat == 1 ? "Ajouter" : "Modifier"} Client
          </div>
          {/* <form className="form-group"> */}
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajoute" : "Modifiez"} le nom de votre client
                et les informations nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>CIN</label>
                <input
                  defaultValue={ObjectClient.cin}
                  type="text"
                  id="CIN"
                  onChange={HandleChange}
                  placeholder="CIN ..."
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Nom</label>
                <input
                  defaultValue={ObjectClient.nom}
                  type="text"
                  id="Nom"
                  placeholder="Nom ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Prenom</label>
                <input
                  defaultValue={ObjectClient.prenom}
                  type="text"
                  id="Prenom"
                  placeholder="Prenom ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Adresse</label>
                <input
                  defaultValue={ObjectClient.adresse}
                  type="text"
                  id="Adresse"
                  placeholder="Rue ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Téléphone</label>
                <input
                  defaultValue={ObjectClient.telephone}
                  type="tel"
                  id="Téléphone"
                  placeholder="06000000"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Ville</label>
                <input
                  defaultValue={ObjectClient.ville}
                  type="tel"
                  id="Ville"
                  placeholder="Ville .."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Email</label>
                <input
                  defaultValue={ObjectClient.email}
                  type="email"
                  id="Email"
                  placeholder="Example@Example.com"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Sexe
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={HandleChange}
                    id="sexe"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio id="sexe" />}
                      label="Homme"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio id="sexe" />}
                      label="Femme"
                      onChange={HandleChange}
                    />
                  </RadioGroup>
                </FormControl>
              </div>{" "}
              {/* <label htmlFor="file-upload2" className="custom-file-upload">
                Télécharger Image
              </label>
              <input
                id="file-upload2"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={OnchangeImage2}
              />
              <img className="img-show" src={s2}></img> */}
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleClick}>
              {props.stat == 1 ? "Crée" : "Modifée"}
            </button>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
};
export default ClientForm;
