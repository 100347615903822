const Delet = (props) => {
  const del = () => {
    // FunctionTwo
    // props.setValueOfDelete(1);
    // props.setvalue(false);
  };
  // LabelOne = FunctionOne, LabelTwo,FunctionTwo,Message,Title
  return (
    <>
      <div className="join">
        <div className="delet-dialog">
          <div className="box-delete">
            {" "}
            <i className="fa-solid fa-trash-can"></i>
            <p>
              {/* Supprimer */}
              {props.Title}
            </p>
            <span className="textCenter">
              {/* Etes-vous sûr que vous voulez supprimer? */}
              {props.Message}
            </span>
            <div className="detet-group">
              <button
                className="btn-g-add m0"
                // onClick={() => {
                //   props.setvalue(false);
                // }}
                onClick={() => {
                  props.FunctionOne();
                }}
              >
                {/* Annuler */}
                {props.LabelOne}
              </button>
              <button
                className="btn-g-add m0 red"
                onClick={() => {
                  props.FunctionTwo();
                }}
              >
                {/* Supprimer */}
                {props.LabelTwo}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Delet;
