import { useSelector } from "react-redux";

export const saveLogin = (user) => {
  localStorage.setItem("adevLogin", JSON.stringify(user));
};

export const GetLogin = () => {
  let dat;
  if (localStorage.getItem("adevLogin")) {
    dat = localStorage.getItem("adevLogin");
    return JSON.parse(dat);
  } else {
    return {
      login: "",
      password: "",
      token: "",
      user: "",
      societe: "",
      parametre: "",
    };
  }
};
export const Logout = () => {
  let dat;
  if (localStorage.getItem("adevLogin")) {
    dat = localStorage.getItem("adevLogin");
    return JSON.parse(dat);
  } else {
    return {
      login: "",
      password: "",
      token: "",
      user: "",
      societe: "",
    };
  }
};

export const getCart = () => {
  let dat;
  if (localStorage.getItem("adevPanier")) {
    dat = localStorage.getItem("adevPanier");
    return JSON.parse(dat);
  } else {
    return {
      items: [],
      total_command: 0,
      total_paiement: 0,
      rest_a_payer: 0,
      nom: null,
      valide: 0,
      prenom: null,
      tele: null,
      nom: null,
      valide: 0,
      prenom: null,
      tele: null,
      remise: 0,
      nbr_items: 0,
      type_assurance: "",
      visite: {
        loin_od_sph: null,
        loin_od_cyl: null,
        loin_od_axe: null,
        loin_og_sph: null,
        loin_og_cyl: null,
        loin_og_axe: null,
        od_ecart: null,
        od_ht: null,
        pres_od_sph: null,
        pres_og_sph: null,
        pres_od_cyl: null,
        pres_og_cyl: null,
        pres_od_axe: null,
        pres_og_axe: null,

        og_ecart: null,
        og_ht: null,
        id_type_viste: 1,
      },
      paiement: {
        type_mode: "Avance",
        id: 1,
        montant: 0,
      },
      client: null,
      client_exist: false,

      client_exist: false,
      typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
    };
  }
};
export const getPaiments = () => {
  let dat;
  let paiments = [];
  if (localStorage.getItem("adevPaiments")) {
    dat = localStorage.getItem("adevPaiments");

    return JSON.parse(dat);
  } else {
    return paiments;
  }
};

export const saveCart = async (p) => {
  localStorage.setItem("adevPanier", JSON.stringify(p));
};
export const savePaiments = async (p) => {
  localStorage.setItem("adevPaiments", JSON.stringify(p));
};
export const addToCart = async (NewItem, EtatPlus) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  let itemIndex = panier.items.findIndex((element) => element.id == NewItem.id);
  if (itemIndex > -1) {
    if (EtatPlus == true) {
      panier.items[itemIndex].qte += 1;
    } else {
      if (panier.items[itemIndex].qte > 1) {
        panier.items[itemIndex].qte -= 1;
      }
    }
  } else {
    panier.items.push(NewItem);
  }
  saveCart(panier);
  updateNbItems();
};

export const updateNbItems = async () => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,

    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };

  panier = getCart();
  let total_calc = 0;
  let total_p = 0;
  panier.items.map((item) => {
    total_calc += item.qte * item.pv;
  });

  total_p += parseFloat(panier.paiement.montant);
  total_calc += parseFloat(panier.typeVerrer.montant);
  total_calc += parseFloat(panier.typeVerrer.montantTow);
  panier.total_command = total_calc;
  total_calc -= parseFloat(panier.remise);
  total_calc -= parseFloat(total_p);
  panier.rest_a_payer = parseFloat(total_calc);
  panier.nbr_items = panier.items.length;
  panier.total_paiement = total_p;
  saveCart(panier);
};

export const deleteItem = async (index) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  panier.items.splice(index, 1);
  saveCart(panier);
  updateNbItems();
};
export const remove = () => {
  localStorage.removeItem("adevPanier");
};
export const AddViste = (viste) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,

      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_od_cyl: null,
      pres_od_axe: null,

      pres_og_sph: null,
      pres_og_cyl: null,
      pres_og_axe: null,
      loin_od_add: null,
      loin_og_add: null,
      pres_od_add: null,
      pres_og_add: null,
      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_viste: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  panier.visite = viste;
  saveCart(panier);
};
export const AddPaiement = (paiement) => {
  savePaiments(paiement);
};
export const AddPaiementViste = (paiement) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();

  panier.paiement = paiement;
  saveCart(panier);
  updateNbItems();
};

export const RemoveViste = () => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();

  panier.visite = null;
  saveCart(panier);
};
export const RemovePaiement = () => {
  savePaiments([]);
};
export const GetCurrentPaiement = () => {
  let paiements = [];
  paiements = getPaiments();
  return paiements;
};
export const RemovePaiementItem = (index) => {
  let paiments = getPaiments();
  paiments.splice(index, 1);
  savePaiments(paiments);
};
export const AddClient = (client) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();

  panier.nom = client.nom;
  panier.prenom = client.prenom;
  panier.tele = client.tele;
  panier.client_exist = client.client_exist;
  panier.client = client.id;
  saveCart(panier);
};
export const AddTypeVerre = (type) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();

  panier.typeVerrer = type;
  saveCart(panier);
  updateNbItems();
};
export const AddFactureValide = (valider) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  panier.valide = valider;
  saveCart(panier);
};
export const AddRmise = (remise) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  panier.remise = remise;
  saveCart(panier);
  updateNbItems();
};
export const AddTypeAssurance = (type) => {
  let panier = {
    items: [],
    total_command: 0,
    total_paiement: 0,
    rest_a_payer: 0,
    nom: null,
    valide: 0,
    prenom: null,
    tele: null,
    remise: 0,
    nbr_items: 0,
    type_assurance: "",
    visite: {
      loin_od_sph: null,
      loin_od_cyl: null,
      loin_od_axe: null,
      loin_og_sph: null,
      loin_og_cyl: null,
      loin_og_axe: null,
      od_ecart: null,
      od_ht: null,
      pres_od_sph: null,
      pres_og_sph: null,
      pres_od_cyl: null,
      pres_og_cyl: null,
      pres_od_axe: null,
      pres_og_axe: null,

      og_ecart: null,
      og_ht: null,
      id_type_viste: 1,
      id_type_verre: 1,
    },
    paiement: {
      type_mode: "Avance",
      id: 1,
      montant: 0,
    },
    client: null,
    client_exist: false,

    typeVerrer: { type: null, montant: 0, typeTow: null, montantTow: 0 },
  };
  panier = getCart();
  panier.type_assurance = type;
  saveCart(panier);
};
