import React from "react";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Loading from "../loading/Loading";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Alert } from "@mui/lab";

export default function Parametre(props) {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(false);
  const [success, setsuccess] = useState(false);
  //
  //Varib Group
  const navigate = useNavigate();

  const [ObjectClient, setObjectClient] = useState({
    facture_num_start: null,
    licence: null,
    date_licence_expire: null,
    n_facture: null,
    password_open_option: null,
    facture_footer: "",
  });

  const HandleChange = (e) => {
    console.log(e.target.value);
    console.log(e.target.id);
    let a = ObjectClient;
    switch (e.target.id) {
      case "facture_num_start":
        a.facture_num_start = e.target.value;
        setObjectClient(a);
        break;
      case "licence":
        a.licence = e.target.value;
        setObjectClient(a);
        break;
      case "date_licence_expire":
        a.date_licence_expire = e.target.value;
        setObjectClient(a);
        break;
      case "n_facture":
        a.n_facture = e.target.value;
        setObjectClient(a);
        break;
      case "password_open_option":
        a.password_open_option = e.target.value;
        setObjectClient(a);
        break;
      case "facture_footer":
        a.facture_footer = e.target.value;
        setObjectClient(a);
        break;
    }
  };

  const GetParametre = async () => {
    axios.get(process.env.REACT_APP_API_URL + "GetParametre").then((res) => {
      setObjectClient(res.data);

      //   sset2(process.env.REACT_APP_IMAGE + "" + res.data.logo);
    });
  };

  const HandleUpdate = async () => {
    const formData = new FormData();
    // facture_num_start: null,
    // licence: null,
    // date_licence_expire: null,
    // n_facture: null,
    // password_open_option: null,
    formData.append("facture_num_start", ObjectClient.facture_num_start);
    formData.append("licence", ObjectClient.licence);
    formData.append("date_licence_expire", ObjectClient.date_licence_expire);
    formData.append("n_facture", ObjectClient.n_facture);
    formData.append("password_open_option", ObjectClient.password_open_option);
    formData.append("facture_footer", ObjectClient.facture_footer);
    console.log(formData);
    axios
      .post(process.env.REACT_APP_API_URL + "UpdateParametre", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((res) => {
        setsuccess(true);
      });
  };
  useEffect(() => {
    GetParametre();
  }, []);
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">Parametre</div>
          {/* <form className="form-group"> */}
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                Modifiez les informations nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Date icence expire</label>
                <input
                  defaultValue={ObjectClient.date_licence_expire}
                  type="date"
                  id="date_licence_expire"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Facture num start</label>
                <input
                  defaultValue={ObjectClient.facture_num_start}
                  type="text"
                  id="facture_num_start"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Licence</label>
                <input
                  defaultValue={ObjectClient.licence}
                  type="text"
                  id="licence"
                  placeholder="Licence"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>N°facture </label>
                <input
                  defaultValue={ObjectClient.n_facture}
                  type="number"
                  id="n_facture"
                  placeholder="N°	 ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Password Option</label>
                <input
                  defaultValue={ObjectClient.password_open_option}
                  type="text"
                  id="password_open_option"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>pied de page de la facture</label>
                <textarea
                  defaultValue={ObjectClient.facture_footer}
                  type="text"
                  id="facture_footer"
                  onChange={HandleChange}
                ></textarea>
              </div>{" "}
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleUpdate}>
              Modifée
            </button>
          </div>
          {success == true ? (
            <Alert severity="success">Socete est modifer </Alert>
          ) : null}

          {/* </form> */}
        </div>
      )}
    </>
  );
}
