import logo from "../assets/logo.png";
import "./admin.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { saveLogin } from "../Services/CartService";
const AdminHome = (props) => {
  const select = useSelector((state) => {
    return state;
  });
  const navigate = useNavigate();
  const [showmenu, setshowmenu] = useState(true);
  useEffect(() => {
    if (select.Token == null) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <div className="admin-container">
        <nav className="nav-admin">
          <div className="logo-404">
            <a href="/" className="logo-link">
              <img src={logo} className="logo_login"></img>
            </a>
          </div>
          <div className="admin-profil">
            {select.User != null ? select.User.name : null}
            <i className="fa-solid fa-user"></i>
            <div
              className="menu_mobile"
              onClick={() => {
                setshowmenu(!showmenu);
              }}
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
        </nav>
        <div className="admin-main">
          {showmenu == true ? (
            <div className="admin-aisde">
              <div className="admin-menu-content">
                {select.User != null ? (
                  <>
                    {select.User.profil == "Admin" ? (
                      <a
                        onClick={() => {
                          navigate("/dashbord");
                        }}
                      >
                        <i className="fa-solid fa-gauge"></i>{" "}
                        <span>Dashbord</span>
                      </a>
                    ) : null}
                  </>
                ) : null}
                <a
                  onClick={() => {
                    navigate("/pos");
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-shop"></i>
                  <span>Vente</span>
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/achat");
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-cart-shopping"></i>{" "}
                  <span>Achat</span>
                </a>
                <a
                  onClick={() => {
                    navigate("/facture");
                  }}
                >
                  <i className="fa-solid fa-file-lines"></i>

                  <span>Facture</span>
                </a>
                <a
                  onClick={() => {
                    navigate("/fournisseur");
                  }}
                >
                  <i className="fa-solid fa-truck-droplet"></i>{" "}
                  <span>Fournisseur</span>
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/produit");
                  }}
                >
                  <i className="fa-brands fa-dropbox"></i>
                  <span>Produit</span>{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/commande");
                  }}
                >
                  <i className="fa-solid fa-file-invoice"></i>{" "}
                  <span>Commandes</span>{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/clients");
                  }}
                >
                  <i className="fa-solid fa-users"></i>
                  <span>Clients</span>{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/caisse");
                  }}
                >
                  <i className="fa-solid fa-cash-register"></i>{" "}
                  <span>Caisse</span>{" "}
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/stock");
                  }}
                >
                  <i className="fa-solid fa-boxes-stacked"></i>
                  <span>Stock</span>
                </a>{" "}
                <a
                  onClick={() => {
                    navigate("/marque");
                  }}
                >
                  <i className="fa-solid fa-marker"></i> <span>Marque</span>
                </a>{" "}
                {select.User != null ? (
                  <>
                    {select.User.profil == "Admin" ? (
                      <a
                        onClick={() => {
                          navigate("/parametre");
                        }}
                      >
                        <i className="fa-solid fa-gear"></i>{" "}
                        <span>Parametre</span>
                      </a>
                    ) : null}
                  </>
                ) : null}
                {select.User != null ? (
                  <>
                    {select.User.profil == "Admin" ? (
                      <a
                        onClick={() => {
                          navigate("/societe");
                        }}
                      >
                        <i className="fa-regular fa-building"></i>
                        <span>Société</span>
                      </a>
                    ) : null}
                  </>
                ) : null}
                {select.User != null ? (
                  <>
                    {select.User.profil == "Admin" ? (
                      <a
                        onClick={() => {
                          navigate("/utilisateur");
                        }}
                      >
                        <i className="fa-solid fa-user-lock"></i>
                        <span>Utilsateur</span>
                      </a>
                    ) : null}
                  </>
                ) : null}
                <a
                  onClick={() => {
                    saveLogin({
                      login: "",
                      password: "",
                      token: "",
                      user: "",
                    });
                    navigate("/");
                  }}
                >
                  <i className="fa-solid fa-right-from-bracket"></i>{" "}
                  <span>Déconnecter</span>
                </a>{" "}
              </div>
            </div>
          ) : null}

          <div className="admin-content">{props.comp}</div>
        </div>
      </div>
    </>
  );
};
export default AdminHome;
