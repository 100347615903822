import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";
import CommandDetail from "./CommandDetail";
import { useSelector } from "react-redux";
import NotFound_Icons from "../assets/no_res.svg";

const Caisse = () => {
  const [rows, setrows] = useState([]);
  const [show, setshow] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [TotalPayement, setTotalPayement] = useState(0);
  const [MsgDelete, setMsgDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [DateAu, setDateAu] = useState(null);
  const [DateDu, setDateDu] = useState(null);
  const [payer, setpayer] = useState(false);
  const select = useSelector((state) => {
    return state;
  });
  const [Filtre, setFiltre] = useState(false);
  const HandleSearchInput = (e) => {
    let a = 0;
    rows
      .filter(
        (row1) =>
          row1.facture.nom
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          row1.facture.prenom
            .toUpperCase()
            .includes(e.target.value.toUpperCase()) ||
          row1.facture.tele.toUpperCase().includes(e.target.value.toUpperCase())
      )
      .map((row) => {
        a = a + parseFloat(row.total);
      });
    setTotalPayement(a);
    setshow(
      rows
        .filter(
          (row1) =>
            row1.facture.nom
              .toUpperCase()
              .includes(e.target.value.toUpperCase()) ||
            row1.facture.prenom
              .toUpperCase()
              .includes(e.target.value.toUpperCase()) ||
            row1.facture.tele
              .toUpperCase()
              .includes(e.target.value.toUpperCase())
        )
        .map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.facture.n_facture}</TableCell>{" "}
            <TableCell>{row.facture.date_facture_vente}</TableCell>{" "}
            <TableCell>
              {row.facture.status == "Payer" ? (
                <Chip
                  color="success"
                  variant="outlined"
                  label={row.facture.status}
                />
              ) : null}{" "}
              {row.facture.status == "Avance" ? (
                <Chip
                  color="secondary"
                  variant="outlined"
                  label={row.facture.status}
                />
              ) : null}{" "}
              {row.facture.status == "Non payer" ? (
                <Chip
                  color="error"
                  variant="outlined"
                  label={row.facture.status}
                />
              ) : null}
            </TableCell>{" "}
            <TableCell>{row.date}</TableCell>{" "}
            <TableCell>
              {row.facture.nom} {row.facture.prenom}
            </TableCell>{" "}
            <TableCell>{row.total}</TableCell>
          </TableRow>
        ))
    );
  };
  const handleChange = (e) => {
    switch (e.target.id) {
      case "du":
        setDateDu(e.target.value);
        break;
      case "au":
        setDateAu(e.target.value);
        break;

        break;
      case "payer":
        setpayer(e.target.value);
        break;
    }
  };
  const fetchData = async () => {
    setLoading1(true);
    await axios
      .post(
        process.env.REACT_APP_API_URL + "GetListPaiement",
        {
          payer: payer,
          DateDu: DateDu,
          DateAu: DateAu,
        },
        {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        }
      )
      .then((resp) => {
        setrows(resp.data);
      
        setLoading1(false);
      });
  };
  const HandleCherche = async () => {
    fetchData();
  };

  useEffect(() => {
    let a = 0;
    rows.map((item) => {
      a = a + parseFloat(item.total);
    });
    setTotalPayement(a);
    setshow(
      rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.facture.n_facture}</TableCell>{" "}
          <TableCell>{row.facture.date_facture_vente}</TableCell>{" "}
          <TableCell>
            {row.facture.status == "Payer" ? (
              <Chip
                color="success"
                variant="outlined"
                label={row.facture.status}
              />
            ) : null}{" "}
            {row.facture.status == "Avance" ? (
              <Chip
                color="secondary"
                variant="outlined"
                label={row.facture.status}
              />
            ) : null}{" "}
            {row.facture.status == "Non payer" ? (
              <Chip
                color="error"
                variant="outlined"
                label={row.facture.status}
              />
            ) : null}
          </TableCell>{" "}
          <TableCell>{row.date}</TableCell>{" "}
          <TableCell>
            {row.facture.nom} {row.facture.prenom}
          </TableCell>{" "}
          <TableCell>{row.total}</TableCell>
        </TableRow>
      ))
    );
  }, [rows]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{MsgDelete}</Alert>
      </Snackbar>{" "}
      <div className="admin-groups">
        <div className="admin-g-s">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="admin-g-titre">
              <h1>Caisse</h1>
            </div>
            <div className="admin-g-search">
              <form className="admin-form">
                <div className="container-search ">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="search"
                    onChange={HandleSearchInput}
                  />
                </div>
              </form>
              <span
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#009F7F",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFiltre(!Filtre);
                }}
              >
                <span> Filtre </span>
                <i
                  className="fa-solid fa-arrow-down"
                  style={{ marginLeft: 4 }}
                ></i>
              </span>
            </div>
          </div>
        </div>
        {Filtre == true ? (
          <>
            <div className="admin-g-s">
              {/* Date */}
              <div>
                <div className="container-search ">
                  Du
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="du"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-search ">
                  Au
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="au"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Payer or not */}
              <div className="admin-g-search">
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",

                    flexDirection: "row",
                  }}
                ></div>
                <button
                  className="btn-g-add"
                  onClick={() => {
                    HandleCherche();
                  }}
                >
                  Recherche
                </button>
              </div>
            </div>
          </>
        ) : null}

        {Loading1 == true ? (
          <Loading />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell>N°Facture</TableCell>
                    <TableCell>Date Facture</TableCell>
                    <TableCell align="left">Status</TableCell>

                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Client</TableCell>

                    <TableCell align="left">Paiement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{show}</TableBody>
              </Table>
              <span
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: 20,
                  fontWeight: "500",
                  padding: 10,
                  color: "#009F7F",
                }}
              >
                Total : {TotalPayement}
              </span>
            </TableContainer>
            {show.length == 0 ? (
              <>
                <div className="not_found_container">
                  <img
                    className="not_found"
                    src={NotFound_Icons}
                    alt="React Logo"
                  />
                  <span className="color_primir">Aucun résultat</span>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default Caisse;
