import axios from "axios";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Loading from "../loading/Loading";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserForm = (props) => {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(true);
  //
  //Varib Group
  const navigate = useNavigate();

  const [ObjectClient, setObjectClient] = useState({
    name: null,
    email: null,
    password: null,
    profil: "Admin",
  });

  useEffect(() => {
    if (props.stat == 2) {
      setObjectClient({
        name: location.state.user.name,
        email: location.state.user.email,
        password: location.state.user.password,
        profil: location.state.user.profil,
      });

      setLoading1(false);
    } else {
      setLoading1(false);
    }
  }, []);
  const HandleChange = (e) => {
  
    let a = ObjectClient;
    switch (e.target.id) {
      case "name":
        a.name = e.target.value;
        setObjectClient(a);
        break;
      case "email":
        a.email = e.target.value;
        setObjectClient(a);
        break;
      case "password":
        a.password = e.target.value;
        setObjectClient(a);
        break;
      case "profil":
        a.profil = e.target.value;
        setObjectClient(a);
        break;
    }
  };
  const HandleClick = async () => {
    // 1 add 2 update
    const formData = new FormData();

    formData.append("name", ObjectClient.name);
    formData.append("email", ObjectClient.email);
    formData.append("password", ObjectClient.password);
    formData.append("profil", ObjectClient.profil);

    if (props.stat == 1) {
      await axios
        .post(process.env.REACT_APP_API_URL + "create_user", formData, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          navigate(-1);
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_API_URL + "edit_user/" + location.state.user.id,
          formData,
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          navigate(-1);
        });
    }
  };
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">
            {props.stat == 1 ? "Ajouter" : "Modifier"} Utilsateur
          </div>
          {/* <form className="form-group"> */}
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajoute" : "Modifiez"} le nom de votre
                Utilsateur et les informations nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Nom</label>
                <input
                  defaultValue={ObjectClient.name}
                  type="text"
                  id="name"
                  onChange={HandleChange}
                  placeholder="Nom ..."
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Email</label>
                <input
                  defaultValue={ObjectClient.email}
                  type="text"
                  id="email"
                  placeholder="Email ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Mote de passe</label>
                <input
                  defaultValue={ObjectClient.password}
                  type="text"
                  id="password"
                  placeholder="Mot de passe ..."
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Role</label>
                <select
                  id="profil"
                  onChange={HandleChange}
                  defaultValue={ObjectClient.profil}
                >
                  <option>Admin</option>
                  <option>Vendeur</option>
                </select>
              </div>{" "}
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleClick}>
              {props.stat == 1 ? "Crée" : "Modifée"}
            </button>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
};
export default UserForm;
