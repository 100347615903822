import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import logo from "../assets/logo.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddArtcile from "./AddArtcile";
import axios from "axios";
import UpdateRemise from "./UpdateRemise";
export default function UpdateCommande() {
  const location = useLocation();
  const [ListDetail, setListDetail] = useState([]);
  const [remise, setremise] = useState(0);
  const [Refreshe, setRefreshe] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [showAdd, setshowAdd] = useState(false);
  const [showremise, setshowremise] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.state.userId);
    setListDetail(location.state.userId.details);
    setremise(location.state.userId.remise);
  }, []);
  const select = useSelector((state) => {
    return state;
  });
  const UpdateFun = async () => {
    console.log(ListDetail);
    if (ListDetail.length > 0) {
      setloadings(true);
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "CommandeUpdateDetails/" +
            location.state.userId.id,
          {
            ListDetail: ListDetail,
          },
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            navigate("/commande");
          }
        });
    }
  };
  const UpdateRemiseFun = async () => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "CommandeUpdateRemise/" +
          location.state.userId.id,
        {
          remise: remise,
        },
        {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          navigate("/commande");
        }
      });
  };
  return (
    <>
      {showAdd == true ? (
        <AddArtcile
          setvalue={setshowAdd}
          listdetail={ListDetail}
          command={location.state.userId}
        />
      ) : null}
      {showremise == true ? (
        <UpdateRemise
          setvalue={setshowremise}
          remise={remise}
          setremise={setremise}
          onclick={() => {
            UpdateRemiseFun();
          }}
        />
      ) : null}
      <nav className="nav-admin">
        <div className="logo-404">
          <a href="/" className="logo-link">
            <img src={logo} className="logo_login"></img>
          </a>
        </div>
        <div className="admin-profil">
          {select.User != null ? select.User.name : null}
          <i className="fa-solid fa-user"></i>
        </div>
      </nav>
      <div className="container_commande_update">
        <div className="side_detail">
          <h4>Client</h4>
          <span>
            Nom :{" "}
            {location.state.userId.nom + " " + location.state.userId.prenom}
          </span>
          <span>Telephone : {location.state.userId.tele}</span>
          {location.state.userId.prescripteur.length > 0 ? (
            <>
              <div className="print-sinature">
                <h4>Mesure</h4>
                <span>
                  Type Viste{" "}
                  {location.state.userId.prescripteur[0].id_type_verre ==
                  "1" ? (
                    "séparé"
                  ) : (
                    <>
                      {location.state.userId.prescripteur[0].id_type_verre ==
                      "2"
                        ? "VL"
                        : "VP"}
                    </>
                  )}
                </span>

                {location.state.userId.prescripteur[0].id_type_verre == "1" ? (
                  <>
                    {" "}
                    <div className="ordenence">
                      <span>
                        <span
                          style={{
                            color: "#26377C",
                            fontWeight: 800,
                          }}
                        >
                          Loin OD
                        </span>{" "}
                        : Sph{" "}
                        {location.state.userId.prescripteur[0].loin_od_sph} Cyl{" "}
                        {location.state.userId.prescripteur[0].loin_od_cyl} Axe{" "}
                        {location.state.userId.prescripteur[0].loin_od_axe} Add{" "}
                        {location.state.userId.prescripteur[0].loin_od_add}
                      </span>
                      <span>
                        <span
                          style={{
                            color: "#26377C",
                            fontWeight: 800,
                          }}
                        >
                          Loin OG
                        </span>{" "}
                        : Sph{" "}
                        {location.state.userId.prescripteur[0].loin_og_sph} Cyl{" "}
                        {location.state.userId.prescripteur[0].loin_og_cyl} Axe{" "}
                        {location.state.userId.prescripteur[0].loin_og_axe} Add{" "}
                        {location.state.userId.prescripteur[0].loin_og_axe}
                      </span>
                      <span>
                        Eip : {location.state.userId.prescripteur[0].od_ecart}
                      </span>
                      <span>
                        H : {location.state.userId.prescripteur[0].od_ht}
                      </span>
                    </div>{" "}
                    <div className="ordenence">
                      <span>
                        <span
                          style={{
                            color: "#26377C",
                            fontWeight: 800,
                          }}
                        >
                          Pres OD
                        </span>{" "}
                        : Sph{" "}
                        {location.state.userId.prescripteur[0].pres_od_sph} Cyl{" "}
                        {location.state.userId.prescripteur[0].pres_od_cyl} Axe{" "}
                        {location.state.userId.prescripteur[0].pres_od_axe}Add{" "}
                        {location.state.userId.prescripteur[0].pres_od_add}
                      </span>
                      <span>
                        <span
                          style={{
                            color: "#26377C",
                            fontWeight: 800,
                          }}
                        >
                          Pres OG
                        </span>{" "}
                        : Sph{" "}
                        {location.state.userId.prescripteur[0].pres_og_sph} Cyl{" "}
                        {location.state.userId.prescripteur[0].pres_og_cyl} Axe{" "}
                        {location.state.userId.prescripteur[0].pres_og_axe} Add{" "}
                        {location.state.userId.prescripteur[0].pres_og_add}
                      </span>
                      <span>
                        Eip : {location.state.userId.prescripteur[0].og_ecart}
                      </span>
                      <span>
                        H : {location.state.userId.prescripteur[0].og_ht}
                      </span>
                    </div>{" "}
                  </>
                ) : (
                  <>
                    {location.state.userId.prescripteur[0].id_type_verre ==
                    "2" ? (
                      <>
                        {" "}
                        <div className="ordenence">
                          <span>
                            <span
                              style={{
                                color: "#26377C",
                                fontWeight: 800,
                              }}
                            >
                              Loin OD
                            </span>{" "}
                            : Sph{" "}
                            {location.state.userId.prescripteur[0].loin_od_sph}{" "}
                            Cyl{" "}
                            {location.state.userId.prescripteur[0].loin_od_cyl}{" "}
                            Axe{" "}
                            {location.state.userId.prescripteur[0].loin_od_axe}
                            Add{" "}
                            {location.state.userId.prescripteur[0].loin_od_add}
                          </span>
                          <span>
                            <span
                              style={{
                                color: "#26377C",
                                fontWeight: 800,
                              }}
                            >
                              Loin OG
                            </span>{" "}
                            : Sph{" "}
                            {location.state.userId.prescripteur[0].loin_og_sph}{" "}
                            Cyl{" "}
                            {location.state.userId.prescripteur[0].loin_og_cyl}{" "}
                            Axe{" "}
                            {location.state.userId.prescripteur[0].loin_og_axe}
                          </span>
                          <span>
                            Eip :{" "}
                            {location.state.userId.prescripteur[0].od_ecart}
                          </span>
                          <span>
                            H : {location.state.userId.prescripteur[0].od_ht}
                          </span>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="ordenence">
                          <span>
                            <span
                              style={{
                                color: "#26377C",
                                fontWeight: 800,
                              }}
                            >
                              Pres OD
                            </span>{" "}
                            : Sph{" "}
                            {location.state.userId.prescripteur[0].pres_od_sph}{" "}
                            Cyl{" "}
                            {location.state.userId.prescripteur[0].pres_od_cyl}{" "}
                            Axe{" "}
                            {location.state.userId.prescripteur[0].pres_od_axe}
                          </span>
                          <span>
                            <span
                              style={{
                                color: "#26377C",
                                fontWeight: 800,
                              }}
                            >
                              Pres OG
                            </span>{" "}
                            : Sph{" "}
                            {location.state.userId.prescripteur[0].pres_og_sph}{" "}
                            Cyl{" "}
                            {location.state.userId.prescripteur[0].pres_og_cyl}{" "}
                            Axe{" "}
                            {location.state.userId.prescripteur[0].pres_og_axe}
                          </span>
                          <span>
                            Eip :{" "}
                            {location.state.userId.prescripteur[0].og_ecart}
                          </span>
                          <span>
                            H : {location.state.userId.prescripteur[0].og_ht}
                          </span>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          ) : null}
          <div className="command-total">
            {" "}
            <div className="total-print">
              <span
                style={{
                  color: "#26377C",
                }}
              >
                Total Net : {location.state.userId.total} DH
              </span>
              <span style={{ color: "#009F7F" }}>
                Avance : {parseFloat(location.state.userId.avance)} DH
              </span>
              <span
                style={{
                  color: "#DC2626",
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Remise : - {location.state.userId.remise}
                DH
                <a
                  onClick={() => {
                    setshowremise(true);
                  }}
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </a>
              </span>{" "}
              <span style={{ color: "#DC2626" }}>
                Total a payer : {location.state.userId.rest_a_payer}
                DH
              </span>
            </div>
          </div>
        </div>
        <div className="commande_content">
          <h4 style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Details</span>
            <span
              onClick={() => {
                setshowAdd(true);
              }}
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 25,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Ajouter
            </span>
          </h4>

          <TableContainer component={Paper}>
            <Table>
              <TableHead className="t-header">
                <TableRow>
                  <TableCell align="center">Produit</TableCell>
                  <TableCell align="center">Qte</TableCell>
                  <TableCell align="center">Prix</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ListDetail.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{row.desgination}</TableCell>{" "}
                    <TableCell align="center">{row.qte}</TableCell>{" "}
                    <TableCell align="center">{row.prix}</TableCell>{" "}
                    <TableCell align="center">
                      {" "}
                      <a
                        className="action-delet"
                        onClick={() => {
                          ListDetail.splice(index, 1);
                          setRefreshe(!Refreshe);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </a>
                    </TableCell>{" "}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loadings == true ? (
            <span
              onClick={() => {}}
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 25,
                fontWeight: "bold",
                cursor: "pointer",
                width: 100,
                marginTop: 30,
              }}
            >
              <CircularProgress />
            </span>
          ) : (
            <span
              onClick={() => {
                UpdateFun();
              }}
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 25,
                fontWeight: "bold",
                cursor: "pointer",
                width: 100,
                marginTop: 30,
              }}
            >
              Modifer
            </span>
          )}
        </div>
      </div>
    </>
  );
}
