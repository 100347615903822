import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MarqueForm = (props) => {
  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [Loading1, setLoading1] = useState(true);
  const [marque, setmarque] = useState(null);
  //
  //Varib Category
  const navigate = useNavigate();

  useEffect(() => {

    if (props.stat == 2) {
      setmarque(location.state.obj.marque);
      setLoading1(false);
    } else {
      setLoading1(false);
    }
  }, []);
  const HandleChange = (e) => {
    switch (e.target.id) {
      case "marque":
        setmarque(e.target.value);
        break;
    }
  };
  const HandleClick = async () => {
    // 1 add 2 update
    const formData = new FormData();
    if (props.stat == 1) {
      if (marque !== null) {
        formData.append("marque", marque);
      }
      await axios
        .post(process.env.REACT_APP_API_URL + "create_marque", formData, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          navigate(-1);
        });
    } else {
      if (marque !== null) {
        formData.append("marque", marque);
      }
      await axios
        .post(
          process.env.REACT_APP_API_URL +
            "edit_marque/" +
            location.state.obj.id,
          {
            marque: marque,
          },
          {
            headers: {
              Authorization: `Bearer ${select.Token}`,
            },
          }
        )
        .then((resp) => {
          navigate(-1);
        });
    }
  };
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="group-titre">
            {props.stat == 1 ? "Ajouter" : "Modifier"} Marque
          </div>
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajouter" : "Modifier"} le nom de votre
                Marque et les informations nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Marque</label>
                <input
                  defaultValue={marque}
                  type="text"
                  id="marque"
                  onChange={HandleChange}
                ></input>
              </div>{" "}
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            <button className="btn-g-add" onClick={HandleClick}>
              {props.stat == 1 ? "Crée" : "Modifée"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default MarqueForm;
