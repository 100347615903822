import { duration } from "@mui/material";
import { format, intervalToDuration } from "date-fns";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
export default function ShowClientDetails(props) {
  const [val, setval] = useState(0);
  const [Data, setData] = useState(null);
  const [Duration, setDuration] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const GetFactureAndPrescripteurs = async () => {
    axios
      .post(process.env.REACT_APP_API_URL + "ClientDetils", {
        id: props.client.id,
      })
      .then((res) => {
        setData(res.data);
      });
  };
  useEffect(() => {
    GetFactureAndPrescripteurs();
    if (props.client.pviste != null) {
      setDuration(
        intervalToDuration({
          start: new Date(props.client.pviste),
          end: new Date(props.client.lviste),
        })
      );
    }
  }, []);

  return (
    <>
      <div className="join">
        <div className="delet-dialog">
          <div className="box-delete">
            <div className="close-menu">
              <div
                className="panier-close"
                onClick={() => {
                  props.setvalue(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>{" "}
            <i className="fa-solid fa-basket-shopping"></i> <p>Details</p>
            <div className="flex-row">
              <div className="flex-column pd">
                {" "}
                <span className="color_primir">
                  {" "}
                  Première Visite :{" "}
                  {format(new Date(props.client.pviste), "dd/MM/yyyy")}
                </span>
                <span className="color_primir">
                  {" "}
                  Dernier Visite :
                  {format(new Date(props.client.lviste), "dd/MM/yyyy")}
                </span>
                <span className="color_primir">
                  {" "}
                  Client depuis : {Duration.years} Ans {Duration.months} mois{" "}
                  {Duration.days}
                </span>
                <span className="color_primir">
                  {" "}
                  Nbr Viste : {props.client.nbrviste}
                </span>
              </div>
              <div className="flex-column pd">
                <span className="color_primir sold_positive">
                  {" "}
                  Total des ventes : {props.client.total_vente} DH
                </span>
                <span className="color_primir">
                  {" "}
                  Total des Réglements : {props.client.total_regelment} DH
                </span>
                <span className="color_primir sold_nigative">
                  {" "}
                  Reste :{" "}
                  {props.client.total_vente - props.client.total_regelment} DH
                </span>
              </div>
            </div>
            {Data != null ? (
              <>
                {Data.length > 0 ? (
                  <>
                    <h4>Commandes</h4>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        style={{ maxHeight: 40 }}
                        aria-label="simple table"
                      >
                        <TableHead className="t-header">
                          <TableRow>
                            <TableCell>N</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Data.map((item, index) => {
                            return (
                              <TableRow
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setval(index);
                                }}
                              >
                                <TableCell>{item.n_facture}</TableCell>
                                <TableCell>{item.date_facture_vente}</TableCell>
                                <TableCell>{item.total}</TableCell>
                                <TableCell>{item.status}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {Data[val].prescripteur.length > 0 ? (
                      <>
                        <div className="print-sinature">
                          <h4>Mesure</h4>
                          <span>
                            Type Viste{" "}
                            {Data[val].prescripteur[0].id_type_verre == "1" ? (
                              "séparé"
                            ) : (
                              <>
                                {Data[val].prescripteur[0].id_type_verre == "2"
                                  ? "VL"
                                  : "VP"}
                              </>
                            )}
                          </span>

                          {Data[val].prescripteur[0].id_type_verre == "1" ? (
                            <>
                              {" "}
                              <div className="ordenence">
                                <span>
                                  <span
                                    style={{
                                      color: "#26377C",
                                      fontWeight: 800,
                                    }}
                                  >
                                    Loin OD
                                  </span>{" "}
                                  : Sph {Data[val].prescripteur[0].loin_od_sph}{" "}
                                  Cyl {Data[val].prescripteur[0].loin_od_cyl}{" "}
                                  Axe {Data[val].prescripteur[0].loin_od_axe}{" "}
                                  Add {Data[val].prescripteur[0].loin_od_add}
                                </span>
                                <span>
                                  <span
                                    style={{
                                      color: "#26377C",
                                      fontWeight: 800,
                                    }}
                                  >
                                    Loin OG
                                  </span>{" "}
                                  : Sph {Data[val].prescripteur[0].loin_og_sph}{" "}
                                  Cyl {Data[val].prescripteur[0].loin_og_cyl}{" "}
                                  Axe {Data[val].prescripteur[0].loin_og_axe}{" "}
                                  Add {Data[val].prescripteur[0].loin_og_axe}
                                </span>
                                <span>
                                  Eip : {Data[val].prescripteur[0].od_ecart}
                                </span>
                                <span>
                                  H : {Data[val].prescripteur[0].od_ht}
                                </span>
                              </div>{" "}
                              <div className="ordenence">
                                <span>
                                  <span
                                    style={{
                                      color: "#26377C",
                                      fontWeight: 800,
                                    }}
                                  >
                                    Pres OD
                                  </span>{" "}
                                  : Sph {Data[val].prescripteur[0].pres_od_sph}{" "}
                                  Cyl {Data[val].prescripteur[0].pres_od_cyl}{" "}
                                  Axe {Data[val].prescripteur[0].pres_od_axe}Add{" "}
                                  {Data[val].prescripteur[0].pres_od_add}
                                </span>
                                <span>
                                  <span
                                    style={{
                                      color: "#26377C",
                                      fontWeight: 800,
                                    }}
                                  >
                                    Pres OG
                                  </span>{" "}
                                  : Sph {Data[val].prescripteur[0].pres_og_sph}{" "}
                                  Cyl {Data[val].prescripteur[0].pres_og_cyl}{" "}
                                  Axe {Data[val].prescripteur[0].pres_og_axe}{" "}
                                  Add {Data[val].prescripteur[0].pres_og_add}
                                </span>
                                <span>
                                  Eip : {Data[val].prescripteur[0].og_ecart}
                                </span>
                                <span>
                                  H : {Data[val].prescripteur[0].og_ht}
                                </span>
                              </div>{" "}
                            </>
                          ) : (
                            <>
                              {Data[val].prescripteur[0].id_type_verre ==
                              "2" ? (
                                <>
                                  {" "}
                                  <div className="ordenence">
                                    <span>
                                      <span
                                        style={{
                                          color: "#26377C",
                                          fontWeight: 800,
                                        }}
                                      >
                                        Loin OD
                                      </span>{" "}
                                      : Sph{" "}
                                      {Data[val].prescripteur[0].loin_od_sph}{" "}
                                      Cyl{" "}
                                      {Data[val].prescripteur[0].loin_od_cyl}{" "}
                                      Axe{" "}
                                      {Data[val].prescripteur[0].loin_od_axe}{" "}
                                      Add{" "}
                                      {Data[val].prescripteur[0].loin_od_add}
                                    </span>
                                    <span>
                                      <span
                                        style={{
                                          color: "#26377C",
                                          fontWeight: 800,
                                        }}
                                      >
                                        Loin OG
                                      </span>{" "}
                                      : Sph{" "}
                                      {Data[val].prescripteur[0].loin_og_sph}{" "}
                                      Cyl{" "}
                                      {Data[val].prescripteur[0].loin_og_cyl}{" "}
                                      Axe{" "}
                                      {Data[val].prescripteur[0].loin_og_axe}
                                    </span>
                                    <span>
                                      Eip : {Data[val].prescripteur[0].od_ecart}
                                    </span>
                                    <span>
                                      H : {Data[val].prescripteur[0].od_ht}
                                    </span>
                                  </div>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <div className="ordenence">
                                    <span>
                                      <span
                                        style={{
                                          color: "#26377C",
                                          fontWeight: 800,
                                        }}
                                      >
                                        Pres OD
                                      </span>{" "}
                                      : Sph{" "}
                                      {Data[val].prescripteur[0].pres_od_sph}{" "}
                                      Cyl{" "}
                                      {Data[val].prescripteur[0].pres_od_cyl}{" "}
                                      Axe{" "}
                                      {Data[val].prescripteur[0].pres_od_axe}
                                    </span>
                                    <span>
                                      <span
                                        style={{
                                          color: "#26377C",
                                          fontWeight: 800,
                                        }}
                                      >
                                        Pres OG
                                      </span>{" "}
                                      : Sph{" "}
                                      {Data[val].prescripteur[0].pres_og_sph}{" "}
                                      Cyl{" "}
                                      {Data[val].prescripteur[0].pres_og_cyl}{" "}
                                      Axe{" "}
                                      {Data[val].prescripteur[0].pres_og_axe}
                                    </span>
                                    <span>
                                      Eip : {Data[val].prescripteur[0].og_ecart}
                                    </span>
                                    <span>
                                      H : {Data[val].prescripteur[0].og_ht}
                                    </span>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
