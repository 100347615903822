import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

import { Pie, Bar } from "react-chartjs-2";
export default function Dashbord() {
  const [ObjectDashBord, setObjectDashBord] = useState({
    nbr_v_par_mois: 0,
    nbr_v_par_jour: 0,
    ca_par_jour: 0,
    ca_par_mois: 0,
  });
  const [Rows, setRows] = useState([]);

  const [LabelsGraphic, setLabelsGraphic] = useState([
    "Mois 1",
    "Mois 2",
    "Mois 3",
    "Mois 4",
    "Mois 5",
    "Mois 6",
    "Mois 7",
    "Mois 8",
    "Mois 9",
    "Mois 10",
    "Mois 11",
    "Mois 12",
  ]);

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        align: "end",
        anchor: "end",
        font: { size: "14" },
      },
    },
    legend: {
      display: false,
    },
  };
  const dataBar = {
    labels: LabelsGraphic,
    datasets: [
      {
        label: "CA par mois",
        backgroundColor: "#EC932F",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: Rows.map((item) => item),
      },
    ],
  };
  useEffect(() => {
    Dash();
  }, []);
  const Dash = async () => {
    axios.get(process.env.REACT_APP_API_URL + "Dashbord").then((res) => {
      setRows(res.data.monthReven);
      setObjectDashBord({
        nbr_v_par_mois: res.data.nbr_v_par_mois,
        nbr_v_par_jour: res.data.nbr_v_par_jour,
        ca_par_jour: res.data.ca_par_jour,
        ca_par_mois: res.data.ca_par_mois,
      });
    });
  };
  return (
    <div className="container-dash2">
      <div className="d-container">
        <div className="info-dash">
          <div className="dash-card">
            <div className="dash-card-container">
              <div className="dash-card-header">
                <div className="dash-card-titre">
                  <span className="d-c-t">Total de chiffre d'affaire</span>
                  <span className="d-c-st">Par jour</span>
                </div>
                <div className="dash-card-icon icon-dash1">
                  <i className="fa-solid fa-money-bill"></i>{" "}
                </div>
              </div>
              <span className="dash-card-num">
                {ObjectDashBord.ca_par_jour} DH
              </span>
            </div>
          </div>
          <div className="dash-card">
            <div className="dash-card-container">
              <div className="dash-card-header">
                <div className="dash-card-titre">
                  <span className="d-c-t">Total Viste</span>
                  <span className="d-c-st">Par Jour</span>
                </div>
                <div className="dash-card-icon icon-dash2">
                  <i className="fa-solid fa-arrow-up-9-1"></i>{" "}
                </div>
              </div>
              <span className="dash-card-num">
                {ObjectDashBord.nbr_v_par_jour}
              </span>
            </div>
          </div>{" "}
          <div className="dash-card">
            <div className="dash-card-container">
              <div className="dash-card-header">
                <div className="dash-card-titre">
                  <span className="d-c-t">Total de chiffre d'affaires</span>
                  <span className="d-c-st">Par Mois</span>
                </div>
                <div className="dash-card-icon icon-dash3">
                  <i className="fa-solid fa-money-bill"></i>{" "}
                </div>
              </div>
              <span className="dash-card-num">
                {ObjectDashBord.ca_par_mois} DH
              </span>
            </div>
          </div>{" "}
          <div className="dash-card">
            <div className="dash-card-container">
              <div className="dash-card-header">
                <div className="dash-card-titre">
                  <span className="d-c-t">Total Viste</span>
                  <span className="d-c-st">Par mois</span>
                </div>
                <div className="dash-card-icon icon-dash4">
                  <i className="fa-solid fa-arrow-up-9-1"></i>{" "}
                </div>
              </div>
              <span className="dash-card-num">
                {ObjectDashBord.nbr_v_par_mois}
              </span>
            </div>
          </div>{" "}
        </div>
        <div style={{ display: "flex", width: "700px", height: "700px" }}>
          <Bar options={options} data={dataBar} />
        </div>
      </div>
    </div>
  );
}
