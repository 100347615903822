import ReactLogo from "./assets/404.svg";
import logo from "./assets/logo.png";
const P404 = () => {
  return (
    <>
      <div className="logo-404">
        <a href="/" className="logo-link">
          <img src={logo} className="logo"></img>
        </a>
      </div>
      <div className="p404">
        {" "}
        <img src={ReactLogo} alt="React Logo" />
        <h3>Désolé, aucun page trouvé :(</h3>
      </div>
    </>
  );
};

export default P404;
