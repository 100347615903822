import { Container } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import logo from "../assets/logo.png";
import { getCart } from "../Services/CartService";
import { connect, useSelector } from "react-redux";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const select = useSelector((state) => {
    return state;
  });
  useEffect(() => {}, []);
  return (
    <Container ref={ref} className="background-print">
      {" "}
      <div className="print-head">
        <img
          src={process.env.REACT_APP_IMAGE + select.societe.logo}
          className="logo-print"
        />

        <div className="print-titre">Fiche atelier</div>
        <div className="print-titre">{format(new Date(), "dd/MM/yyyy")}</div>
      </div>
      <div className="info-print">
        {props.panier.client != null ? (
          <>
            <span>N°Client : {props.panier.client}</span>
          </>
        ) : null}
        <span>Client : {props.panier.nom + " " + props.panier.prenom}</span>
        <span>Telephone : {props.panier.tele}</span>
      </div>
      <table className="print-table" id="customers">
        <thead className="thead-print">
          <th>Produit</th>
          <th>Qte</th>
          <th>Prix</th>
          <th>Total</th>
        </thead>
        <tbody className="tbody-print" style={{ marginBottom: 10 }}>
          {props.panier.items.map((row) => (
            <tr>
              <td>{row.desgination}</td>
              <td>{row.qte}</td>
              <td>{row.pv}</td>
              <td>{row.qte * row.pv} DH</td>
            </tr>
          ))}
          {props.panier.typeVerrer.type != null ? (
            <tr>
              <td>{props.panier.typeVerrer.type}</td>
              <td>1</td>
              <td>{props.panier.typeVerrer.montant}</td>
              <td>{props.panier.typeVerrer.montant} DH</td>
            </tr>
          ) : null}
          {props.panier.typeVerrer.typeTow != null ? (
            <tr>
              <td>{props.panier.typeVerrer.typeTow}</td>
              <td>1</td>
              <td>{props.panier.typeVerrer.montantTow}</td>
              <td>{props.panier.typeVerrer.montantTow} DH</td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <div className="total-print">
        <span style={{ color: "#26377C" }}>
          Total Net : {props.panier.total_command} DH
        </span>
        <span className="avance">
          Avance : {parseFloat(props.panier.total_paiement)} DH
        </span>
        <span className="remise">
          Remise : - {props.panier.remise}
          DH
        </span>
        <span className="remise">
          Total a payer : {parseFloat(props.panier.rest_a_payer)}
          DH
        </span>{" "}
      </div>
      <div className="print-sinature">
        <h4>Mesure</h4>
        <span>
          Type Viste{" "}
          {props.panier.visite.id_type_viste == "1" ? (
            "séparé"
          ) : (
            <>{props.panier.visite.id_type_viste == "2" ? "VL" : "VP"}</>
          )}
        </span>

        {props.panier.visite.id_type_viste == "1" ? (
          <>
            <div style={{ paddingTop: 10 }}>
              <div className="ordenence">
                <span>
                  <span style={{ color: "#26377C", fontWeight: 800 }}>
                    Loin OD
                  </span>{" "}
                  : Sph {props.panier.visite.loin_od_sph} Cyl{" "}
                  {props.panier.visite.loin_od_cyl} Axe{" "}
                  {props.panier.visite.loin_od_axe} Add{" "}
                  {props.panier.visite.loin_od_add}
                </span>
                <span>
                  <span style={{ color: "#26377C", fontWeight: 800 }}>
                    Loin OG
                  </span>{" "}
                  : Sph {props.panier.visite.loin_og_sph} Cyl{" "}
                  {props.panier.visite.loin_og_cyl} Axe{" "}
                  {props.panier.visite.loin_og_axe} Add{" "}
                  {props.panier.visite.loin_og_axe}
                </span>
                <span>Eip : {props.panier.visite.od_ecart}</span>
                <span>H : {props.panier.visite.od_ht}</span>
              </div>
              <div className="ordenence">
                <span>
                  <span style={{ color: "#26377C", fontWeight: 800 }}>
                    Pres OD
                  </span>{" "}
                  : Sph {props.panier.visite.pres_od_sph} Cyl{" "}
                  {props.panier.visite.pres_od_cyl} Axe{" "}
                  {props.panier.visite.pres_od_axe}Add{" "}
                  {props.panier.visite.pres_od_add}
                </span>
                <span>
                  <span style={{ color: "#26377C", fontWeight: 800 }}>
                    Pres OG
                  </span>{" "}
                  : Sph {props.panier.visite.pres_og_sph} Cyl{" "}
                  {props.panier.visite.pres_og_cyl} Axe{" "}
                  {props.panier.visite.pres_og_axe} Add{" "}
                  {props.panier.visite.pres_og_add}
                </span>
                <span>Eip : {props.panier.visite.og_ecart}</span>
                <span>H : {props.panier.visite.og_ht}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            {props.panier.visite.id_type_viste == "2" ? (
              <>
                {" "}
                <div className="ordenence">
                  <span>
                    <span style={{ color: "#26377C", fontWeight: 800 }}>
                      Loin OD
                    </span>{" "}
                    : Sph {select.viste.loin_od_sph} Cyl{" "}
                    {select.viste.loin_od_cyl} Axe {select.viste.loin_od_axe}{" "}
                    Add {select.viste.loin_od_add}
                  </span>
                  <span>
                    <span style={{ color: "#26377C", fontWeight: 800 }}>
                      Loin OG
                    </span>{" "}
                    : Sph {select.viste.loin_og_sph} Cyl{" "}
                    {select.viste.loin_og_cyl} Axe {select.viste.loin_og_axe}
                  </span>
                  <span>Eip : {select.viste.od_ecart}</span>
                  <span>H : {select.viste.od_ht}</span>
                </div>{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="ordenence">
                  <span>
                    <span style={{ color: "#26377C", fontWeight: 800 }}>
                      Pres OD
                    </span>{" "}
                    : Sph {select.viste.pres_od_sph} Cyl{" "}
                    {select.viste.pres_od_cyl} Axe {select.viste.pres_od_axe}
                  </span>
                  <span>
                    <span style={{ color: "#26377C", fontWeight: 800 }}>
                      Pres OG
                    </span>{" "}
                    : Sph {select.viste.pres_og_sph} Cyl{" "}
                    {select.viste.pres_og_cyl} Axe {select.viste.pres_og_axe}
                  </span>
                  <span>Eip : {select.viste.og_ecart}</span>
                  <span>H : {select.viste.og_ht}</span>
                </div>
              </>
            )}
          </>
        )}
        <div className="hide_me">
          <div className="print-head">
            <img
              src={process.env.REACT_APP_IMAGE + select.societe.logo}
              className="logo-print"
            />
            <div className="print-titre">Reçu </div>
            <div className="print-titre">
              {format(new Date(), "dd/MM/yyyy")}
            </div>
          </div>
          <div className="info-print">
            <span>Client : {props.panier.nom + " " + props.panier.prenom}</span>
            <span>Telephone : {props.panier.tele}</span>
          </div>
          <div className="total-print">
            <span style={{ color: "#000" }}>
              Total Net : {props.panier.total_command} DH
            </span>
            <span style={{ color: "#000" }}>
              Avance : {parseFloat(props.panier.total_paiement)} DH
            </span>
            <span style={{ color: "#000" }}>
              Remise : - {props.panier.remise}
              DH
            </span>
            <span style={{ color: "#000" }}>
              Total a payer : {parseFloat(props.panier.rest_a_payer)}
              DH
            </span>{" "}
          </div>
          <div className="ordenence">
            <span>{select.societe.raison_sociale}</span>
            <span>{select.societe.adresse}</span>
            <span>{select.societe.tele}</span>
            <span>{select.societe.email}</span>
          </div>
        </div>
      </div>
    </Container>
  );
});

class PrintCommande extends React.PureComponent {
  constructor() {
    super();
    this.state = { token: "" };
    this.AddCommande = this.AddCommande.bind(this);
  }
  async AddCommande() {
    let a = getCart();

    axios
      .post(
        process.env.REACT_APP_API_URL + "commande",
        {
          type_assurance: a.type_assurance,
          valide: a.valide,
          nom: a.nom,
          prenom: a.prenom,
          tele: a.tele,
          items: a.items,
          paiement: a.paiement,
          visite: this.props.viste,
          total_command: parseFloat(a.total_command),
          total_paiement: parseFloat(a.total_paiement),
          rest_a_payer: parseFloat(a.rest_a_payer),
          type_verre: a.typeVerrer,
          remise: parseFloat(a.remise),
          user: this.props.User,
          client_id: a.client,
          client_exist: a.client_exist,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
          },
        }
      )
      .then((res) => {});
  }
  async componentDidMount() {}
  render() {
    return (
      <div className="join">
        <div className="delet-dialog">
          <div className="box-delete1">
            <header className="h-panier">
              <div className="t-i-panier">
                <i className="fa-solid fa-pager"></i> <span>Facture</span>
              </div>
              <div
                className="panier-close"
                onClick={() => {
                  this.props.setprintcommande(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </header>
            <ComponentToPrint
              ref={(el) => (this.componentRef = el)}
              panier={this.props.panier}
              datesearch={this.props.datesearch}
            />
            <span
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactToPrint
                trigger={() => {
                  return (
                    <span className="print-btn" style={{ cursor: "pointer" }}>
                      <i className="fas fa-print"></i> Imprimer
                    </span>
                  );
                }}
                content={() => this.componentRef}
                onAfterPrint={() => {
                  this.AddCommande();
                  this.props.setredirectTOclient(true);
                }}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(PrintCommande);
function mapStateToProps(state) {
  return {
    token: state.Token,
    User: state.User,
    viste: state.viste,
  };
}
