import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";
import CommandDetail from "./CommandDetail";
import { useSelector } from "react-redux";
import NotFound_Icons from "../assets/no_res.svg";
import DetailReception from "./DetailReception";
import { useNavigate } from "react-router-dom";

const Reception = () => {
  const [rows, setrows] = useState([]);
  const [show, setshow] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [TotalPayement, setTotalPayement] = useState(0);
  const [MsgDelete, setMsgDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [DateAu, setDateAu] = useState(null);
  const [DateDu, setDateDu] = useState(null);
  const [payer, setpayer] = useState(false);
  const [ShowDetailReception, setShowDetailReception] = useState(false);
  const [Rece, setRece] = useState(null);
  const navigate = useNavigate();

  const select = useSelector((state) => {
    return state;
  });
  const [Filtre, setFiltre] = useState(false);
  const HandleSearchInput = (e) => {
    let a = 0;
    let filt = [];
    filt = rows.filter((item) => {
      return (
        (item.fournisseur.nom || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.fournisseur.prenom || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.n_facture || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        (item.fournisseur.societe || "")
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    filt.map((row) => {
      a = a + parseFloat(row.total);
    });
    setTotalPayement(a);
    setshow(
      filt.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.n_facture}</TableCell>{" "}
          <TableCell>{row.note}</TableCell>{" "}
          <TableCell>{row.date_factur_achat}</TableCell>{" "}
          <TableCell>{row.fournisseur.societe}</TableCell>{" "}
          <TableCell>{row.user.name}</TableCell>
          <TableCell>
            {" "}
            <a
              className="action-eye ml-10"
              onClick={() => {
                setRece(row);
                setShowDetailReception(true);
              }}
            >
              <i className="fa-regular fa-eye"></i>{" "}
            </a>{" "}
          </TableCell>
        </TableRow>
      ))
    );
  };
  const handleChange = (e) => {
    switch (e.target.id) {
      case "du":
        setDateDu(e.target.value);
        break;
      case "au":
        setDateAu(e.target.value);
        break;

        break;
      case "payer":
        setpayer(e.target.value);
        break;
    }
  };
  const fetchData = async () => {
    setLoading1(true);
    await axios
      .post(
        process.env.REACT_APP_API_URL + "ListAchats",
        {
          payer: payer,
          DateDu: DateDu,
          DateAu: DateAu,
        },
        {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        }
      )
      .then((resp) => {
        setrows(resp.data);

        setLoading1(false);
      });
  };
  const HandleCherche = async () => {
    fetchData();
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    let a = 0;
    rows.map((item) => {
      a = a + parseFloat(item.total);
    });
    setTotalPayement(a);
    setshow(
      rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.n_facture}</TableCell>{" "}
          <TableCell>{row.note}</TableCell>{" "}
          <TableCell>{row.date_factur_achat}</TableCell>{" "}
          <TableCell>
            {row.fournisseur != null ? row.fournisseur.societe : null}
          </TableCell>{" "}
          <TableCell>{row.user.name}</TableCell>
          <TableCell>
            {" "}
            <a
              className="action-eye ml-10"
              onClick={() => {
                setRece(row);
                setShowDetailReception(true);
              }}
            >
              <i className="fa-regular fa-eye"></i>{" "}
            </a>{" "}
          </TableCell>
        </TableRow>
      ))
    );
  }, [rows]);

  return (
    <>
      {ShowDetailReception == true ? (
        <DetailReception Rece={Rece} setvalue={setShowDetailReception} />
      ) : null}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{MsgDelete}</Alert>
      </Snackbar>{" "}
      <div className="admin-groups">
        <div className="admin-g-s">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="admin-g-titre">
              <h1>Reception</h1>
            </div>
            <div className="admin-g-search">
              <form className="admin-form">
                <div className="container-search ">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="search"
                    onChange={HandleSearchInput}
                  />
                </div>
              </form>
              <span
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#009F7F",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFiltre(!Filtre);
                }}
              >
                <span> Filtre </span>
                <i
                  className="fa-solid fa-arrow-down"
                  style={{ marginLeft: 4 }}
                ></i>
              </span>
              <button
                className="btn-g-add"
                onClick={() => {
                  navigate("/achat/ajouter");
                }}
              >
                + Reception
              </button>
            </div>
          </div>
        </div>
        {Filtre == true ? (
          <>
            <div className="admin-g-s">
              {/* Date */}
              <div>
                <div className="container-search ">
                  Du
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="du"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-search ">
                  Au
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="au"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Payer or not */}
              <div className="admin-g-search">
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",

                    flexDirection: "row",
                  }}
                ></div>
                <button
                  className="btn-g-add"
                  onClick={() => {
                    HandleCherche();
                  }}
                >
                  Recherche
                </button>
              </div>
            </div>
          </>
        ) : null}
        {Loading1 == true ? (
          <Loading />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell>N°Reception </TableCell>
                    <TableCell>Note </TableCell>
                    <TableCell>Date</TableCell>

                    <TableCell align="left">Fournisseur</TableCell>
                    <TableCell align="left">Cree Par</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{show}</TableBody>
              </Table>
            </TableContainer>
            {show.length == 0 ? (
              <>
                <div className="not_found_container">
                  <img
                    className="not_found"
                    src={NotFound_Icons}
                    alt="React Logo"
                  />
                  <span className="color_primir">Aucun résultat</span>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default Reception;
