import axios from "axios";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert, MenuItem, Select, Snackbar } from "@mui/material";

const ProduitForm = (props) => {
  const [s2, sset2] = useState(null);

  const location = useLocation();
  const select = useSelector((state) => {
    return state;
  });
  const [ObjectProduit, setObjectProduit] = useState({
    desgination: null,
    reference: null,
    photo: null,
    qte_prod: null,
    couler: null,
    taille: null,
    duree: null,
    pv: null,
    pa: null,
    description: null,
    note: null,
    code_bar: null,
    id_marque: null,
    id_categorie: null,
    stock_max: null,
    stock_min: null,
  });
  const OnchangeImage2 = (e) => {
    let a = ObjectProduit;
    sset2(URL.createObjectURL(e.target.files[0]));
    a.photo = e.target.files[0];
    setObjectProduit(a);
  };
  const [MarqueList, setMarqueList] = useState([]);
  const [CateList, setCateList] = useState([]);

  const [Loading1, setLoading1] = useState(true);
  const [Loading2, setLoading2] = useState(false);
  //
  const [Error, setError] = useState("");
  const [open, setOpen] = useState(false);
  //Varib Category
  const navigate = useNavigate();
  const MarqueCategoryes = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "marques", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((respo) => {
        setMarqueList(
          respo.data.map((row) => {
            return (
              <MenuItem key={row.id} value={row.id}>
                <span> {row.marque}</span>
              </MenuItem>
            );
          })
        );
      });
    await axios
      .get(process.env.REACT_APP_API_URL + "categories", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((respo) => {
        setCateList(
          respo.data.map((row) => {
            return (
              <MenuItem key={row.id} value={row.id}>
                <span> {row.categorie}</span>
              </MenuItem>
            );
          })
        );
      });
  };
  useEffect(() => {
    if (CateList.length < 1) {
      MarqueCategoryes();
    }
    if (props.stat == 2) {
      sset2(process.env.REACT_APP_IMAGE + "" + location.state.obj.photo);
      setObjectProduit({
        desgination: location.state.obj.desgination,
        reference: location.state.obj.reference,
        photo: location.state.obj.photo,
        qte_prod: location.state.obj.qte_prod,
        couler: location.state.obj.couler,
        taille: location.state.obj.taille,
        duree: location.state.obj.duree,
        pv: location.state.obj.pv,
        pa: location.state.obj.pa,
        description: location.state.obj.description,
        note: location.state.obj.note,
        code_bar: location.state.obj.code_bar,
        id_marque: location.state.obj.id_marque,
        id_categorie: location.state.obj.id_categorie,
        stock_max: location.state.obj.stock_max,
        stock_min: location.state.obj.stock_min,
      });
      setLoading1(false);
    } else {
      setLoading1(false);
    }
  }, []);
  const HandleChange = (e) => {
    let a = ObjectProduit;
    switch (e.target.id) {
      case "codebar":
        a.code_bar = e.target.value;
        setObjectProduit(a);
        break;
      case "designation":
        a.desgination = e.target.value;
        setObjectProduit(a);
        break;
      case "reference":
        a.reference = e.target.value;
        setObjectProduit(a);
        break;
      case "description":
        a.description = e.target.value;
        setObjectProduit(a);
        break;
      case "note":
        a.note = e.target.value;
        setObjectProduit(a);
        break;
      case "qte_prod":
        a.qte_prod = e.target.value;
        setObjectProduit(a);
        break;
      case "pa":
        a.pa = e.target.value;
        setObjectProduit(a);
        break;
      case "pv":
        a.pv = e.target.value;
        setObjectProduit(a);
        break;
      case "stock_max":
        a.stock_max = e.target.value;
        setObjectProduit(a);
        break;
      case "stock_min":
        a.stock_min = e.target.value;
        setObjectProduit(a);
        break;
    }
  };
  const HandleClick = async () => {
    if (
      ObjectProduit.desgination == null ||
      ObjectProduit.desgination == "" ||
      ObjectProduit.pv == 0 ||
      ObjectProduit.pv == null ||
      ObjectProduit.pv == ""
    ) {
      setOpen(true);
      setError(
        "veuillez ajouter la désgination du produit et le prix de vente"
      );
    } else {
      setLoading2(true);
      const formData = new FormData();
      if (ObjectProduit.photo !== null) {
        formData.append("photo", ObjectProduit.photo);
      }
      if (ObjectProduit.desgination !== null) {
        formData.append("desgination", ObjectProduit.desgination);
      }
      if (ObjectProduit.reference !== null) {
        formData.append("reference", ObjectProduit.reference);
      }
      if (ObjectProduit.qte_prod !== null) {
        formData.append("qte_prod", ObjectProduit.qte_prod);
      }
      if (ObjectProduit.couler !== null) {
        formData.append("couler", ObjectProduit.couler);
      }
      if (ObjectProduit.taille !== null) {
        formData.append("taille", ObjectProduit.taille);
      }
      if (ObjectProduit.duree !== null) {
        formData.append("duree", ObjectProduit.duree);
      }
      if (ObjectProduit.pv !== null) {
        formData.append("pv", ObjectProduit.pv);
      }
      if (ObjectProduit.pa !== null) {
        formData.append("pa", ObjectProduit.pa);
      }
      if (ObjectProduit.description !== null) {
        formData.append("description", ObjectProduit.description);
      }
      if (ObjectProduit.note !== null) {
        formData.append("note", ObjectProduit.note);
      }
      if (ObjectProduit.stock_max !== null) {
        formData.append("stock_max", ObjectProduit.stock_max);
      }
      if (ObjectProduit.stock_min !== null) {
        formData.append("stock_min", ObjectProduit.stock_min);
      }
      if (ObjectProduit.code_bar !== null) {
        formData.append("code_bar", ObjectProduit.code_bar);
      }
      if (ObjectProduit.id_marque !== null) {
        formData.append("id_marque", ObjectProduit.id_marque);
      }
      if (ObjectProduit.id_categorie !== null) {
        formData.append("id_categorie", ObjectProduit.id_categorie);
      }

      if (props.stat == 1) {
        await axios
          .post(process.env.REACT_APP_API_URL + "create_article", formData, {
            headers: {
              Authorization: `Bearer ${select.Token}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            navigate(-1);
          });
      } else {
        const formData = new FormData();

        if (ObjectProduit.photo !== null) {
          formData.append("photo", ObjectProduit.photo);
        }
        if (ObjectProduit.desgination !== null) {
          formData.append("desgination", ObjectProduit.desgination);
        }
        if (ObjectProduit.reference !== null) {
          formData.append("reference", ObjectProduit.reference);
        }
        if (ObjectProduit.qte_prod !== null) {
          formData.append("qte_prod", ObjectProduit.qte_prod);
        }
        if (ObjectProduit.couler !== null) {
          formData.append("couler", ObjectProduit.couler);
        }
        if (ObjectProduit.taille !== null) {
          formData.append("taille", ObjectProduit.taille);
        }
        if (ObjectProduit.duree !== null) {
          formData.append("duree", ObjectProduit.duree);
        }
        if (ObjectProduit.pv !== null) {
          formData.append("pv", ObjectProduit.pv);
        }
        if (ObjectProduit.pa !== null) {
          formData.append("pa", ObjectProduit.pa);
        }
        if (ObjectProduit.description !== null) {
          formData.append("description", ObjectProduit.description);
        }
        if (ObjectProduit.note !== null) {
          formData.append("note", ObjectProduit.note);
        }
        if (ObjectProduit.stock_max !== null) {
          formData.append("stock_max", ObjectProduit.stock_max);
        }
        if (ObjectProduit.stock_min !== null) {
          formData.append("stock_min", ObjectProduit.stock_min);
        }
        if (ObjectProduit.code_bar !== null) {
          formData.append("code_bar", ObjectProduit.code_bar);
        }
        if (ObjectProduit.id_marque !== null) {
          formData.append("id_marque", ObjectProduit.id_marque);
        }
        if (ObjectProduit.id_categorie !== null) {
          formData.append("id_categorie", ObjectProduit.id_categorie);
        }

        await axios
          .post(
            process.env.REACT_APP_API_URL +
              "edit_article/" +
              location.state.obj.id,
            formData,
            {
              headers: {
                Authorization: `Bearer ${select.Token}`,
              },
            }
          )
          .then((resp) => {
            navigate(-1);
          });
      }
    }
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  return (
    <>
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {Error}
            </Alert>
          </Snackbar>

          <div className="group-titre">
            {props.stat == 1 ? "Ajouter" : "Modifier"} Produit
          </div>
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Image</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajouter" : "Modifier"} Téléchargez votre
                photo de produit à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <label htmlFor="file-upload2" className="custom-file-upload">
                Télécharger Image
              </label>
              <input
                id="file-upload2"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={OnchangeImage2}
              />
              <img className="img-show" src={s2}></img>
            </div>
          </div>
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Description</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajouter" : "Modifier"} la Désignation de
                votre offre et les informations nécessaires à partir d'ici
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Code Bar</label>
                <input
                  type="text"
                  id="codebar"
                  defaultValue={ObjectProduit.code_bar}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Désignation</label>
                <input
                  type="text"
                  id="designation"
                  defaultValue={ObjectProduit.desgination}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Référence</label>
                <input
                  type="text"
                  id="reference"
                  onChange={HandleChange}
                  defaultValue={ObjectProduit.reference}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Description</label>
                <textarea
                  id="description"
                  onChange={HandleChange}
                  defaultValue={ObjectProduit.description}
                  rows="8"
                ></textarea>
              </div>{" "}
              <div className="input-form">
                <label>Note</label>
                <textarea
                  id="note"
                  defaultValue={ObjectProduit.note}
                  onChange={HandleChange}
                  rows="8"
                ></textarea>
              </div>{" "}
            </div>
          </div>
          <div className="group-g-modifcation">
            <div className="g-g-m-t">
              <h4 className="g-g-m-tt">Gestion</h4>
              <p className="g-g-m-tt">
                {props.stat == 1 ? "Ajouter" : "Modifier"} gestion produit
              </p>
            </div>
            <div className="g-g-m-content">
              <div className="input-form">
                <label>Quantity</label>
                <input
                  type="number"
                  id="qte_prod"
                  defaultValue={ObjectProduit.qte_prod}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Prix achat</label>
                <input
                  type="number"
                  id="pa"
                  min="0.00"
                  max="10000.00"
                  step="0.01"
                  defaultValue={ObjectProduit.pa}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Prix vente</label>
                <input
                  type="number"
                  id="pv"
                  min="0.00"
                  max="10000.00"
                  step="0.01"
                  defaultValue={ObjectProduit.pv}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Stock min</label>
                <input
                  type="number"
                  id="stock_min"
                  min="0.00"
                  max="10000.00"
                  step="0.01"
                  defaultValue={ObjectProduit.stock_min}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Stock max</label>
                <input
                  type="number"
                  id="stock_max"
                  min="0.00"
                  max="10000.00"
                  step="0.01"
                  defaultValue={ObjectProduit.stock_max}
                  onChange={HandleChange}
                ></input>
              </div>{" "}
              <div className="input-form">
                <label>Categories</label>

                <Select
                  id="cat"
                  defaultValue={ObjectProduit.id_categorie}
                  onChange={(e) => {
                    let a = ObjectProduit;
                    a.id_categorie = e.target.value;
                    setObjectProduit(a);
                  }}
                >
                  {CateList}
                </Select>
              </div>{" "}
              <div className="input-form">
                <label>Marque</label>
                <Select
                  id="marque"
                  defaultValue={ObjectProduit.id_marque}
                  onChange={(e) => {
                    let a = ObjectProduit;
                    a.id_marque = e.target.value;
                    setObjectProduit(a);
                  }}
                >
                  {MarqueList}
                </Select>
              </div>
            </div>
          </div>

          <div className="group-button">
            <button
              className="group-b-b"
              onClick={() => {
                navigate(-1);
              }}
            >
              Arrière
            </button>
            {Loading2 == true ? (
              <button className="btn-g-add">...</button>
            ) : (
              <button
                className="btn-g-add"
                onClick={() => {
                  HandleClick();
                }}
              >
                {props.stat == 1 ? "Crée" : "Modifée"}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default ProduitForm;
