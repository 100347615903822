import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NotFound_Icons from "../assets/no_res.svg";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  AddPaiement,
  getCart,
  GetCurrentPaiement,
  getPaiments,
  remove,
  RemovePaiement,
  RemovePaiementItem,
  savePaiments,
} from "../Services/CartService";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
export default function ShowClientFacture(props) {
  const [show, setshow] = useState(null);
  const [showpa, setshowpa] = useState(null);
  const [loading, setloading] = useState(false);
  const [Rece, setRece] = useState(null);
  const [FormPayer, setFormPayer] = useState(false);
  const [RefreshT, setRefreshT] = useState(false);
  const navigate = useNavigate();

  const [ObjectPaiment, setObjectPaiment] = useState({
    type_mode: "Avance",
    id: 1,
    montant: 0,
  });
  const HandleChange = (e) => {
    let a = ObjectPaiment;
    a.montant = parseFloat(e.target.value);
    setObjectPaiment(a);
  };
  const handleAddPaiement = () => {
    let a = GetCurrentPaiement();
    a.push(ObjectPaiment);
    AddPaiement(a);
    setRefreshT(!RefreshT);
  };

  useEffect(() => {
    RemovePaiement();
    setshow(
      props.client.paiements.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.date}</TableCell>{" "}
          <TableCell>
            {row.id_mode_payement == 2 ? (
              <Chip color="secondary" variant="outlined" label="Espece" />
            ) : null}{" "}
            {row.id_mode_payement == 1 ? (
              <Chip color="secondary" variant="outlined" label="Avance" />
            ) : null}{" "}
            {row.id_mode_payement == 3 ? (
              <Chip color="secondary" variant="outlined" label="Cheque" />
            ) : null}
          </TableCell>{" "}
          <TableCell>{row.total}</TableCell>
          <TableCell>{props.client.n_facture}</TableCell>
        </TableRow>
      ))
    );
  }, []);
  useEffect(() => {
    let a = GetCurrentPaiement();
    if (Array.isArray(a) == false) {
      RemovePaiement();
      a = [];
    }
    setshowpa(
      a.map((row, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{row.type_mode}</TableCell>
            <TableCell>{row.montant}</TableCell>
            <TableCell>{format(new Date(), "MM/dd/yyyy")}</TableCell>{" "}
            <TableCell align="right">
              <div className="group-action">
                <a
                  className="action-delet"
                  onClick={() => {
                    RemovePaiementItem(index);
                    setRefreshT(!RefreshT);
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </a>
              </div>
            </TableCell>
          </TableRow>
        );
      })
    );
  }, [RefreshT]);
  const AddPaimentToFacture = async () => {
    setloading(true);
    let a = getPaiments();
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "AddPaiementTofacture/" +
          props.client.id,
        {
          paiement: a,
          facture: props.client,
        }
      )
      .then((res) => {
        RemovePaiement();
        props.setvalue(false);
      });
  };
  return (
    <div className="join">
      <div className="delet-dialog">
        <div className="box-delete">
          <h2 style={{ textAlign: "center" }}>
            {props.client.nom} {props.client.prenom}
          </h2>
          <TableContainer component={Paper} style={{ overflow: "scroll" }}>
            <button
              className="btn-g-add m0"
              style={{ float: "right", marginTop: 10 }}
              onClick={() => {
                setFormPayer(true);
              }}
            >
              Ajouter Payement
            </button>
            <Table>
              <TableHead className="t-header">
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Mode</TableCell>
                  <TableCell>Montant</TableCell>
                  <TableCell>Facture</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{show}</TableBody>
            </Table>
          </TableContainer>
          {show == null ? (
            <>
              <div className="not_found_container">
                <img
                  className="not_found"
                  src={NotFound_Icons}
                  alt="React Logo"
                />
                <span className="color_primir">Aucun résultat</span>
              </div>
            </>
          ) : null}

          {FormPayer == true ? (
            <>
              <h2 style={{ textAlign: "center" }}>
                Rest a payer : {props.client.rest_a_payer} DH
              </h2>
              <div className="minform">
                <div className="group_min">
                  <Select
                    id="cat"
                    defaultValue={ObjectPaiment.id}
                    onChange={(e) => {
                      let a = ObjectPaiment;
                      a.id = e.target.value;
                      switch (e.target.value) {
                        case "1":
                          a.type_mode = "Avance";
                          break;
                        case "2":
                          a.type_mode = "Espace";
                          break;
                        case "3":
                          a.type_mode = "Cheque";
                          break;
                      }
                      setObjectPaiment(a);
                    }}
                  >
                    <MenuItem value="1" data-my-value="Avance">
                      <span>Avance</span>
                    </MenuItem>{" "}
                    <MenuItem value="2" data-my-value="Espace">
                      <span>Espace</span>
                    </MenuItem>{" "}
                    <MenuItem value="3" data-my-value="Cheque">
                      <span>Cheque</span>
                    </MenuItem>
                  </Select>
                  <input
                    onChange={HandleChange}
                    placeholder="montant"
                    type="number"
                    id="montant"
                    min="0"
                  />
                  <span
                    onClick={() => {
                      handleAddPaiement();
                    }}
                    style={{
                      padding: 10,
                      background: "#009F7F",
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      borderRadius: 25,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Ajouter
                  </span>
                </div>
              </div>
              <TableContainer component={Paper} style={{ overflow: "scroll" }}>
                <Table>
                  <TableHead className="t-header">
                    <TableRow>
                      <TableCell>Mode</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{showpa != null ? showpa : null}</TableBody>
                </Table>
              </TableContainer>
              {loading == true ? (
                <button
                  className="btn-g-add m0"
                  style={{ float: "right", marginTop: 10 }}
                  onClick={() => {}}
                >
                  En Coure de traitement
                </button>
              ) : (
                <button
                  className="btn-g-add m0"
                  style={{ float: "right", marginTop: 10 }}
                  onClick={() => {
                    AddPaimentToFacture();
                  }}
                >
                  Valide Payement
                </button>
              )}
            </>
          ) : null}
          <div className="detet-group">
            <button
              className="btn-g-add m0 red"
              onClick={() => {
                props.setvalue(false);
              }}
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
