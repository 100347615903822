import logo from "../assets/panier.svg";

const PanierVide = () => {
  return (
    <>
      <div className="panier-vide">
        {" "}
        <img src={logo}></img>
        <h4>Aucun produit trouvé</h4>
      </div>
    </>
  );
};
export default PanierVide;
