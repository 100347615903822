import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";
import CommandDetail from "./CommandDetail";
import { useSelector } from "react-redux";
import NotFound_Icons from "../assets/no_res.svg";
import ShowClientFacture from "./ShowClientFacture";
import Delet from "./Delet";
import { useNavigate } from "react-router-dom";

const CommandTrait = () => {
  const [rows, setrows] = useState([]);
  const [show, setshow] = useState([]);
  const [ShowDetailReception, setShowDetailReception] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [MsgDelete, setMsgDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const [Rece, setRece] = useState(null);
  const [DateAu, setDateAu] = useState(null);
  const [DateDu, setDateDu] = useState(null);
  const [search, setsearch] = useState("");
  const [dateB, setdateB] = useState(false);
  const [payer, setpayer] = useState(false);
  const [showfacture, setshowfacture] = useState(false);
  const [item, setitems] = useState([]);
  const [Delet1, setDelet1] = useState(false);
  const [ValueOfDelete, setValueOfDelete] = useState(0);
  const [ChangeEtatCommande, setChangeEtatCommande] = useState(false);
  const navigate = useNavigate();

  const select = useSelector((state) => {
    return state;
  });
  const [Filtre, setFiltre] = useState(false);
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        HandleCherche();
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });
  const handleChange = (e) => {
    switch (e.target.id) {
      case "du":
        setDateDu(e.target.value);
        break;
      case "au":
        setDateAu(e.target.value);
        break;
      case "datec":
        setdateB(e.target.value);
        break;
      case "payer":
        setpayer(e.target.value);
        break;
    }
  };
  const fetchData = async () => {
    setLoading1(true);
    await axios
      .post(
        process.env.REACT_APP_API_URL + "ListCommande",
        {
          search: search,
          payer: payer,
          DateDu: DateDu,
          DateAu: DateAu,
        },
        {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        }
      )
      .then((resp) => {
        setrows(resp.data);
        setLoading1(false);
      });
  };
  const HandleCherche = async () => {
    fetchData();
  };
  const BecomeFacture = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "UpdateCommande/" + item.id, {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((resp) => {
        if (resp.status == 200) {
          fetchData();
        }
      });
  };
  useEffect(() => {
    if (showfacture == false) {
      HandleCherche();
    }
  }, [ShowDetailReception, showfacture]);
  useEffect(() => {
    setshow(
      rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.n_facture}</TableCell>{" "}
          <TableCell>{row.date_facture_vente}</TableCell>{" "}
          <TableCell>
            {row.nom} {row.prenom}
          </TableCell>{" "}
          <TableCell>
            {row.status == "Payer" ? (
              <Chip color="success" variant="outlined" label={row.status} />
            ) : null}{" "}
            {row.status == "Avance" ? (
              <Chip color="secondary" variant="outlined" label={row.status} />
            ) : null}{" "}
            {row.status == "Non payer" ? (
              <Chip color="error" variant="outlined" label={row.status} />
            ) : null}
          </TableCell>{" "}
          <TableCell>{row.total}</TableCell>
          <TableCell>{row.remise}</TableCell>{" "}
          <TableCell>{row.avance}</TableCell>
          <TableCell>{row.rest_a_payer}</TableCell>
          <TableCell>{row.user.name}</TableCell>
          <TableCell>
            {" "}
            <a
              className="action-eye ml-10"
              onClick={() => {
                setRece(row);
                setShowDetailReception(true);
              }}
            >
              <i className="fa-regular fa-eye"></i>{" "}
            </a>{" "}
            <a
              className="action-eye ml-10"
              onClick={() => {
                setshowfacture(true);
                setitems(row);
              }}
            >
              <i className="fa-solid fa-money-bill"></i>{" "}
            </a>
            {select.User.profil == "Admin" ? (
              <>
                {" "}
                <a
                  className="action-delet"
                  onClick={() => {
                    setDelet1(!Delet1);
                    setitems(row);
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </a>
              </>
            ) : null}
            {row.valide == false ? (
              <a
                className="action-eye ml-10"
                onClick={() => {
                  setitems(row);
                  setChangeEtatCommande(true);
                }}
              >
                <i className="fa-solid fa-file-invoice"></i>
              </a>
            ) : null}
            <a
              className=" ml-10"
              onClick={() => {
                navigate("/modife_commande", {
                  state: {
                    userId: row,
                  },
                });
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </a>{" "}
          </TableCell>{" "}
        </TableRow>
      ))
    );
  }, [rows]);
  useEffect(() => {
    if (ValueOfDelete == 0) {
    } else {
      axios
        .delete(process.env.REACT_APP_API_URL + "DestroyCommande/" + item.id, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((resp) => {
          if (resp.status == 200) {
            fetchData();
          }
        });

      setValueOfDelete(0);
    }
  }, [ValueOfDelete]);
  return (
    <>
      {showfacture == true ? (
        <ShowClientFacture setvalue={setshowfacture} client={item} />
      ) : null}
      {ShowDetailReception == true ? (
        <CommandDetail Rece={Rece} setvalue={setShowDetailReception} />
      ) : null}
      {Delet1 == true ? (
        <Delet
          // setValueOfDelete={setValueOfDelete}
          // setvalue={setDelet1}
          Title={"Supprimer"}
          Message={"Etes-vous sûr que vous voulez supprimer?"}
          LabelOne={"Annuler"}
          FunctionOne={() => {
            setDelet1(false);
          }}
          LabelTwo={"Supprimer"}
          FunctionTwo={() => {
            setValueOfDelete(1);
            setDelet1(false);
          }}
        />
      ) : null}{" "}
      {ChangeEtatCommande == true ? (
        <Delet
          Title={"Facture"}
          Message={
            "êtes-vous sûr de vouloir mettre à jour la commande pour facturer ?"
          }
          LabelOne={"Annuler"}
          FunctionOne={() => {
            setChangeEtatCommande(false);
          }}
          LabelTwo={"Facture"}
          FunctionTwo={() => {
            BecomeFacture();
            setChangeEtatCommande(false);
          }}
        />
      ) : null}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{MsgDelete}</Alert>
      </Snackbar>{" "}
      <div className="admin-groups">
        <div className="admin-g-s">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="admin-g-titre">
              <h1>Commandes</h1>
            </div>
            <div className="admin-g-search">
              <form className="admin-form">
                <div className="container-search ">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="search"
                    onChange={(e) => {
                      setsearch(e.target.value);
                    }}
                  />
                </div>
              </form>
              <span
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#009F7F",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setFiltre(!Filtre);
                }}
              >
                <span> Filtre </span>
                <i
                  className="fa-solid fa-arrow-down"
                  style={{ marginLeft: 4 }}
                ></i>
              </span>
              <button
                className="btn-g-add"
                onClick={() => {
                  HandleCherche();
                }}
              >
                Recherche
              </button>
            </div>
          </div>
        </div>
        {Filtre == true ? (
          <>
            <div className="admin-g-s">
              {/* Date */}
              <div>
                <div className="container-search ">
                  Du
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="du"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-search ">
                  Au
                  <input
                    type="date"
                    className="search-input-mobile w-full"
                    placeholder="Client,Date,Note..."
                    id="au"
                    onChange={handleChange}
                  />
                </div>

                <span> status :</span>
                <select
                  id="payer"
                  onChange={handleChange}
                  className="search-input-mobile"
                >
                  <option value="Tous">Tous</option>

                  <option value="Payer">Payer</option>
                  <option value="Non payer">Non payer</option>
                  <option value="Avance">Avance</option>
                </select>
              </div>
              {/* Payer or not */}
              <div className="admin-g-search">
                <div
                  style={{
                    marginLeft: 20,
                    display: "flex",

                    flexDirection: "row",
                  }}
                ></div>
              </div>
            </div>
          </>
        ) : null}
        {Loading1 == true ? (
          <Loading />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell>N°Facture</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Client</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Total</TableCell>
                    <TableCell align="left">Remise</TableCell>
                    <TableCell align="left">Avance</TableCell>
                    <TableCell align="left">Total a payer</TableCell>
                    <TableCell align="left">Cree par</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{show}</TableBody>
              </Table>
            </TableContainer>
            {show.length == 0 ? (
              <>
                <div className="not_found_container">
                  <img
                    className="not_found"
                    src={NotFound_Icons}
                    alt="React Logo"
                  />
                  <span className="color_primir">Aucun résultat</span>
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default CommandTrait;
