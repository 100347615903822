import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Loading from "../loading/Loading";
import axios from "axios";
import Delet from "./Delet";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useSelector } from "react-redux";
import ShowClientDetails from "./ShowClientDetails";
import NotFound_Icons from "../assets/no_res.svg";
import ShowClientFacture from "./ShowClientFacture";

const User = () => {
  const [rows, setrows] = useState([]);
  const [item, setitems] = useState([]);
  const [show, setshow] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Delet1, setDelet1] = useState(false);
  const [showdetail, setshowdetail] = useState(false);
  const [showfacture, setshowfacture] = useState(false);
  const [ValueOfDelete, setValueOfDelete] = useState(0);
  const [MsgDelete, setMsgDelete] = useState(null);
  const [open, setOpen] = useState(false);
  const select = useSelector((state) => {
    return state;
  });
  const HandleSearch = (e) => {
    setshow(
      rows
        .filter(
          (row1) =>
            row1.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            row1.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            row1.profil.toLowerCase().includes(e.target.value.toLowerCase())
        )
        .map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.profil}</TableCell>

            <TableCell align="right">
              <div className="group-action">
                <a
                  className="action-delet"
                  onClick={() => {
                    setDelet1(!Delet1);
                    setitems(row);
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </a>
                <a
                  className="action-edit ml-10"
                  onClick={() => {
                    navigate("/user/edit", {
                      state: {
                        user: row,
                      },
                    });
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </a>
              </div>
            </TableCell>
          </TableRow>
        ))
    );
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(process.env.REACT_APP_API_URL + "GetUsers", {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((resp) => {
          setrows(resp.data);
          if (resp.data.length > 0) {
            setshow(
              resp.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.profil}</TableCell>

                  <TableCell align="right">
                    <div className="group-action">
                      <a
                        className="action-delet"
                        onClick={() => {
                          setDelet1(!Delet1);
                          setitems(row);
                        }}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </a>
                      <a
                        className="action-edit ml-10"
                        onClick={() => {
                          navigate("/user/edit", {
                            state: {
                              user: row,
                            },
                          });
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </a>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            );
          } else {
            setshow(null);
          }
          setLoading1(false);
        });
    };
    fetchData();
  }, [setshowfacture]);

  useEffect(() => {
    if (ValueOfDelete == 0) {
    } else {
      axios
        .delete(process.env.REACT_APP_API_URL + "destroy_user/" + item.id, {
          headers: {
            Authorization: `Bearer ${select.Token}`,
          },
        })
        .then((resp) => {
          setrows(resp.data);
          setshow(
            resp.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.profil}</TableCell>

                <TableCell align="right">
                  <div className="group-action">
                    <a
                      className="action-delet"
                      onClick={() => {
                        setDelet1(!Delet1);
                        setitems(row);
                      }}
                    >
                      <i className="fa-solid fa-trash-can"></i>
                    </a>
                    <a
                      className="action-edit ml-10"
                      onClick={() => {
                        navigate("/user/edit", {
                          state: {
                            user: row,
                          },
                        });
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </a>
                  </div>
                </TableCell>
              </TableRow>
            ))
          );
        });

      setValueOfDelete(0);
    }
  }, [ValueOfDelete]);

  return (
    <>
      {" "}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error">{MsgDelete}</Alert>
      </Snackbar>{" "}
      {Delet1 == true ? (
        <Delet
          // setValueOfDelete={setValueOfDelete}
          // setvalue={setDelet1}
          Title={"Supprimer"}
          Message={"Etes-vous sûr que vous voulez supprimer?"}
          LabelOne={"Annuler"}
          FunctionOne={() => {
            setDelet1(false);
          }}
          LabelTwo={"Supprimer"}
          FunctionTwo={() => {
            setValueOfDelete(1);
            setDelet1(false);
          }}
        />
      ) : null}
      {Loading1 == true ? (
        <Loading />
      ) : (
        <div className="admin-groups">
          <div className="admin-g-s">
            <div className="admin-g-titre">
              <h1>Utilisateur</h1>
            </div>
            <div className="admin-g-search">
              <form className="admin-form">
                <div className="container-search ">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <input
                    className="search-input-mobile w-full"
                    placeholder="Nom,Email,Tele ..."
                    id="search"
                    onChange={HandleSearch}
                  />
                </div>
              </form>

              <button
                className="btn-g-add"
                onClick={() => {
                  navigate("/user/ajouter");
                }}
              >
                + Utilisateur
              </button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="t-header">
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Profil</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{show}</TableBody>
            </Table>
          </TableContainer>
          {show == null ? (
            <>
              <div className="not_found_container">
                <img
                  className="not_found"
                  src={NotFound_Icons}
                  alt="React Logo"
                />
                <span className="color_primir">Aucun résultat</span>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
export default User;
