import axios from "axios";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const DetailReception = (props) => {
  const [show, setshow] = useState([]);

  useEffect(() => {
    setshow(
      props.Rece.details.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.desgination}</TableCell>{" "}
          <TableCell align="center">{row.qte}</TableCell>{" "}
          <TableCell align="center">{row.prix}</TableCell>{" "}
        </TableRow>
      ))
    );
  }, []);
  return (
    <>
      <div className="join">
        <div className="recptipn-container">
          <article>
            <div className="close-menu">
              <div
                className="panier-close"
                onClick={() => {
                  props.setvalue(false);
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <center>
              <h4>Detail Reception</h4>
            </center>
            {/* {props.Rece.note} */}
            <div className="rece-header">
              <div className="rec-date">
                Date Reception: {props.Rece.date_factur_achat}
              </div>
              <div className="rec-date">Num : {props.Rece.n_facture}</div>

              <div className="rec-date">
                Fournisseur : {props.Rece.fournisseur.nom}
                {props.Rece.fournisseur.prenom}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="t-header">
                  <TableRow>
                    <TableCell align="center">Produit</TableCell>
                    <TableCell align="center">Qte</TableCell>
                    <TableCell align="center">Prix</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{show}</TableBody>
              </Table>
              <div className="command-total">
                {" "}
                <div className="total-print">
                  <span style={{ color: "#26377C" }}>
                    Total Net : {props.Rece.total} DH
                  </span>
                </div>
              </div>
            </TableContainer>
          </article>
        </div>
      </div>
    </>
  );
};
export default DetailReception;
