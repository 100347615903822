import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

export default function UpdateFacture(props) {
  const [show, setshow] = useState([]);
  const [rows, setrows] = useState([]);
  const [Details, setDetails] = useState([]);
  const [Loading, setloading] = useState(false);
  const [Fact, setFact] = useState({ id: 0, total_facture: 0 });
  useEffect(() => {
    setFact({
      id: props.facture.id,
      total_facture: props.facture.total_facture,
    });
    setDetails(props.facture.details);
    setrows(props.facture.details);
  }, []);
  useEffect(() => {
    setshow(
      rows.map((row, index) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.desgination}</TableCell>{" "}
          <TableCell align="center">{row.qte}</TableCell>{" "}
          <TableCell align="center">{row.prix}</TableCell>{" "}
          <TableCell align="center">
            <input
              type="number"
              min="1"
              step="any"
              id={index}
              defaultValue={row.prix_facture}
              onChange={HandleChange}
            ></input>
          </TableCell>{" "}
        </TableRow>
      ))
    );
  }, [rows]);

  const UpdateFact = async () => {
    setloading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "UpdateFacture", {
        facture: Fact,
        details: Details,
      })
      .then((res) => {
        props.setvalue(false);
      });
  };

  const HandleChange = (e) => {
    let a = Details;
    Object.keys(a[e.target.id]).forEach((key, index) => {
      if (key == "prix_facture") {
        a[e.target.id][key] = e.target.value;
      }
    });
    setDetails(a);
  };

  return (
    <div className="join">
      <div className="delet-dialog">
        <div className="box-delete">
          <div className="close-menu">
            <div
              className="panier-close"
              onClick={() => {
                props.setvalue(false);
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>{" "}
          <center>
            <h4>Detail Commande</h4>
          </center>
          {/* {props.facture.note} */}
          <div className="facture-header">
            <div className="rec-date">
              Date Facture: {props.facture.date_facture_vente}
            </div>
            <div className="rec-date">Num : {props.facture.n_facture}</div>

            <div className="rec-date">
              Client : {props.facture.nom} {props.facture.prenom}
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className="t-header">
                <TableRow>
                  <TableCell align="center">Produit</TableCell>
                  <TableCell align="center">Qte</TableCell>
                  <TableCell align="center">Prix</TableCell>
                  <TableCell align="center">Prix Facture</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{show}</TableBody>
            </Table>
            <div className="command-total">
              {" "}
              <div className="total-print">
                <span style={{ color: "#26377C" }}>
                  Total Net : {props.facture.total} DH
                </span>
              </div>
              <span style={{ color: "#26377C", fontSize: 12 }}>
                Total Facture :{" "}
                <input
                  defaultValue={props.facture.total_facture}
                  id="total_noir"
                  type="number"
                  min="1"
                  step="any"
                  onChange={(e) => {
                    let b = Fact;
                    b.total_facture = e.target.value;
                    setFact(b);
                  }}
                ></input>{" "}
                DH
              </span>
            </div>
          </TableContainer>{" "}
          {Loading == true ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <span
              onClick={() => {
        
                UpdateFact();
              }}
              style={{
                padding: 10,
                background: "#009F7F",
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                borderRadius: 25,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Modifer
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
