import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo from "../assets/logo.png";
import Monture from "../assets/monture.png";
import Lnes from "../assets/lens.png";
import Autre from "../assets/other.png";
import Accessory from "../assets/accessory.png";
import Glass from "../assets/glass.png";
import {
  addToCart,
  deleteItem,
  getCart,
  remove,
} from "../Services/CartService";
import PanierVide from "../Components/PanierVide";
import axios from "axios";
import Loading from "../loading/Loading";
import { useNavigate } from "react-router-dom";
import PrintCommande from "./PrintCommande";
import StepForm from "./StepForm";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
export default function Pos() {
  const select = useSelector((state) => {
    return state;
  });
  const navigate = useNavigate();
  const [Error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [search, setsearch] = useState("");
  const [redirectTOclient, setredirectTOclient] = useState(false);
  const [viste, setviste] = useState(false);
  const [printcommande, setprintcommande] = useState(false);
  const [Panier, setPanier] = useState({
    items: [
      {
        id: null,
        desgination: 0,
        qte: 0,
        pv: null,
      },
    ],
    total_command: null,
    nbr_items: null,
    remise: null,
  });
  const [Articles, setArticles] = useState(null);
  const [show, setshow] = useState(null);
  const [Refresh, setRefresh] = useState(false);
  const [CatSelected, setCatSelected] = useState({ name: "Monture", id: 1 });
  const GestArticles = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "articles", {
        headers: {
          Authorization: `Bearer ${select.Token}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setArticles(res.data);
          console.log("ar ", res.data);
        }
      });
  };
  const AddToPanier = (item, etat) => {
    addToCart(
      {
        id: item.id,
        desgination: item.desgination,
        qte: 1,
        pv: item.pv,
        image: item.photo,
      },
      etat
    );
    let a = getCart();
    setPanier(a);
    setRefresh(!Refresh);
  };
  useEffect(() => {
    if (select.Token == null) {
      navigate("/");
    }
    let a = getCart();
    setPanier(a);
    GestArticles();
  }, []);
  useEffect(() => {
    if (Articles !== null) {
      let a = [];
      let b = [];
      a = Articles.filter((row) => row.id_categorie == CatSelected.id);

      b = a.filter(
        (item) =>
          (item.desgination || "")
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          (item.reference || "").toLowerCase().includes(search.toLowerCase())
      );

      setshow(
        b.map((item, index) => {
          return (
            <div className="pos_article" key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src={process.env.REACT_APP_IMAGE + "" + item.photo}
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: 25,
                  }}
                />
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    paddingLeft: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{item.desgination}</span>
                  <span style={{ fontSize: 12 }}>{item.reference}</span>
                  <span style={{ fontWeight: "bold" }}>{item.pv} DH</span>
                </div>
              </div>
              {item.qte_prod > 0 ? (
                <span
                  style={{
                    marginTop: 10,
                    padding: 10,
                    background: "#009F7F",
                    color: "#fff",
                    textAlign: "center",
                    borderRadius: 99999,
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    AddToPanier(item, true);
                  }}
                >
                  Ajouter au Panier
                </span>
              ) : (
                <span
                  style={{
                    marginTop: 10,
                    padding: 10,
                    background: "red",
                    color: "#fff",
                    textAlign: "center",
                    borderRadius: 99999,
                    cursor: "not-allowed",
                    fontWeight: "bold",
                  }}
                >
                  Rupture de stock
                </span>
              )}
            </div>
          );
        })
      );
    }
    if (redirectTOclient == true) {
      remove();

      navigate("/commande");
    }
  }, [CatSelected, Articles, redirectTOclient, search]);
  const AddCommande = async () => {
    let a = getCart();
    console.log("getCart ", a);
    if (a.client_exist == true) {
      setPanier(a);
      setprintcommande(true);
    } else {
      if (a.nom == null || a.prenom == null || a.tele == null) {
        setError("merci de remplir le formulaire viste (client)");
        setOpen(true);
      } else {
        setPanier(a);
        setprintcommande(true);
      }
    }
  };

  const handleClose = (event, reason) => {
    setOpen(false);
  };
  return (
    <div>
      {viste == true ? (
        <StepForm setviste={setviste} setPanier={setPanier} />
      ) : null}
      {printcommande == true ? (
        <PrintCommande
          setprintcommande={setprintcommande}
          panier={Panier}
          setredirectTOclient={setredirectTOclient}
        />
      ) : null}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {Error}
        </Alert>
      </Snackbar>
      <div className="admin-container">
        <nav className="nav-admin">
          <div className="logo-404">
            <a href="/" className="logo-link">
              <img src={logo} className="logo_login"></img>
            </a>
          </div>
          <div
            className="admin-profil"
            onClick={() => {
              navigate("/dashbord");
            }}
          >
            <i className="fa-solid fa-user"></i>
            {select.User != null ? select.User.name : null}
          </div>
        </nav>
      </div>
      <div className="pos_content">
        <div className="pos_right">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Choise Category</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                background: "#fff",
                padding: 10,
                marginRight: 10,
                borderRadius: 30,
                height: 20,
              }}
            >
              <i
                class="fa-solid fa-magnifying-glass"
                style={{ cursor: "pointer" }}
              ></i>
              <input
                placeholder="Recherche ..."
                style={{ border: "none" }}
                onChange={(e) => {
                  setsearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="pos_container_category">
            <div
              className="pos_cat_item"
              onClick={() => {
                setCatSelected({ name: "Monture", id: 1 });
              }}
            >
              <img src={Monture}></img>
              <span>Monture</span>
            </div>{" "}
            <div
              className="pos_cat_item"
              onClick={() => {
                setCatSelected({ name: "Verre", id: 3 });
              }}
            >
              <img src={Glass}></img>
              <span>Verre</span>
            </div>{" "}
            <div
              className="pos_cat_item"
              onClick={() => {
                setCatSelected({ name: "Lentille", id: 2 });
              }}
            >
              <img src={Lnes}></img>
              <span>Lentille</span>
            </div>{" "}
            <div
              className="pos_cat_item"
              onClick={() => {
                setCatSelected({ name: "Accessory", id: 4 });
              }}
            >
              <img src={Accessory}></img>
              <span>Accessory</span>
            </div>{" "}
            <div
              className="pos_cat_item"
              onClick={() => {
                setCatSelected({ name: "Autre", id: 5 });
              }}
            >
              <img src={Autre}></img>
              <span>Autre</span>
            </div>
          </div>
          <h2>{CatSelected.name} Menu</h2>
          <div className="pos_container_article">
            {Articles == null ? (
              <div style={{ width: "100%", marginBottom: 40 }}>
                <Loading />
              </div>
            ) : (
              <>{show}</>
            )}
          </div>
        </div>

        <div className="pos_left">
          <div className="s-panier">
            <div className="content-panier">
              <header className="h-panier">
                <div className="t-i-panier">
                  <i className="fa-solid fa-basket-shopping"></i>
                  <span>{Panier.nbr_items} items</span>
                </div>
                <span
                  style={{
                    color: "#DC2626",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    remove();
                    let a = getCart();
                    setPanier(a);
                  }}
                >
                  <i class="fa-solid fa-broom"></i>
                </span>
              </header>{" "}
              <header className="h-panier">
                <div
                  className="t-i-panier"
                  style={{
                    cursor: "pointer",
                    color: "#122d55",
                  }}
                >
                  <i class="fa-regular fa-address-card"></i>{" "}
                  <span> Ajouter viste</span>
                </div>{" "}
                <div
                  className="t-i-panier"
                  style={{
                    cursor: "pointer",
                    color: "#122d55",
                    height: 10,
                    width: 10,
                  }}
                  onClick={() => {
                    setviste(!viste);
                  }}
                >
                  <i className="fa-solid fa-plus"></i>{" "}
                </div>
              </header>{" "}
              <div className="panier-content">
                {Panier.items.length == 0 ? (
                  <PanierVide />
                ) : (
                  <>
                    {Panier.items.map((row, index) => {
                      return (
                        <div className="panier-items">
                          <div className="item-c-p">
                            <div className="item-c-content">
                              <button
                                className="btn-qte"
                                onClick={() => {
                                  AddToPanier(row, false);
                                }}
                              >
                                -
                              </button>
                              <span>{row.qte}</span>
                              <button
                                className="btn-qte"
                                onClick={() => {
                                  AddToPanier(row, true);
                                }}
                              >
                                +
                              </button>
                            </div>
                            <div className="item-p-img">
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE + "" + row.image
                                }
                              ></img>
                            </div>
                            <div className="item-c-detail">
                              <span>{row?.desgination}</span>
                              <p className="item-c-price">{row.pv} DH</p>
                              <span className="item-c-q">
                                {row.qte} X unite
                              </span>
                            </div>
                            <div className="item-c-total">
                              {row.qte * row.pv} DH
                            </div>
                            <div className="item-c-del">
                              <i
                                className="fa-solid fa-xmark"
                                onClick={() => {
                                  deleteItem(index);
                                  let a = getCart();
                                  setPanier(a);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              <div
                className="panier-checkout"
                onClick={(e) => {
                  AddCommande();
                }}
              >
                <div>
                  <span className="checkout-panier">Commande</span>
                  <span className="panier-total">
                    {Panier.total_command} DH
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
