import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetLogin, saveLogin } from "../Services/CartService";

const Login = (props) => {
  const [L, setL] = useState("");
  const [M, setM] = useState("");
  const [Error, setError] = useState(null);
  const [_loading, set_loading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    nbrbiste();
    let obj = GetLogin();
    if (obj.login != "") {
      setL(obj.login);
      setM(obj.password);
      props.formLogin(obj.user, obj.token, obj.societe, obj.parametre);
      axios.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${obj.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      if (obj.user.profil == "Admin") {
        navigate("/dashbord");
      } else {
        navigate("/caisse");
      }
    }
  }, []);
  const HandleLogin = async () => {
    set_loading(true);

    try {
      const formData = new FormData();
      formData.append("name", L);
      formData.append("password", M);
      await axios
        .post(process.env.REACT_APP_API_URL + "login", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((resp) => {
          console.log(resp.data.data);
          if (resp.data.success == true) {
            saveLogin({
              login: L,
              password: M,
              token: resp.data.data.token,
              user: resp.data.data.user,
              societe: resp.data.data.societe,
              parametre: resp.data.data.parametre,
            });
            props.formLogin(
              resp.data.data.user,
              resp.data.data.token,
              resp.data.data.societe,
              resp.data.data.parametre
            );

            axios.interceptors.request.use(
              (config) => {
                config.headers.Authorization = `Bearer ${resp.data.data.token}`;
                return config;
              },
              (error) => {
                return Promise.reject(error);
              }
            );

            if (resp.data.data.user.profil != "Admin") {
              navigate("/caisse");
            } else {
              navigate("/dashbord");
            }
          } else {
            setError("Le nom d'utilisateur ou le mot de passe est incorrect");
            set_loading(false);
          }
        });
    } catch (error) {
      setError("Probleme serveur");
      set_loading(true);
    }
  };
  const nbrbiste = async () => {
    axios
      .post(process.env.REACT_APP_API_URL + "NbrViste")
      .then((res) => {
        console.log("nbr ", res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        HandleLogin();
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });
  return (
    <div className="ins-container">
      {/* {AdminP == true ? <Navigate to="/admin" /> : null}
      {UserP == true ? <Navigate to="/utilsateur/command" /> : null}
      {LivreurP == true ? <Navigate to="/livreur/command" /> : null} */}
      <img className="logo_login" src={require("../assets/logo.png")} />
      <p className="login-titre">
        Connectez-vous avec votre nom et votre mot de passe
      </p>
      <div className="login-row">
        <label>Utilisateur</label>
        <input
          defaultValue={L}
          type="name"
          id="name"
          onChange={(e) => {
            setL(e.target.value);
          }}
        ></input>
      </div>{" "}
      <div className="login-row">
        <label>Password</label>
        <input
          defaultValue={M}
          type="password"
          id="password"
          onChange={(e) => {
            setM(e.target.value);
          }}
        ></input>
      </div>
      <div className="btn-login" onClick={HandleLogin}>
        {_loading == false ? <span>Login</span> : <CircularProgress />}
      </div>
      {Error !== null ? <span className="error">{Error}</span> : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ListPanier: state.ListPanier,
    d_user: state.d_user,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    formLogin: (User, Token, societe, parametre) => {
      dispatch({
        type: "ACTION_LOGIN",
        User: User,
        Token: Token,
        societe: societe,
        parametre: parametre,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
